import { Component, HostListener, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
    FormControl,
    FormBuilder,
    ValidatorFn,
    Validators,
} from "@angular/forms";
import { fuseAnimations } from "@fuse/animations";
import { ToastrService } from "ngx-toastr";
import { MatTableDataSource } from "@angular/material/table";
import * as _ from "underscore";
import { calculateAutoOrderService } from "../../shared/calculateAutoOrder.service";
import { RgetRegexService } from "../../shared/getRegex.service";
import { FuseSplashScreenService } from "../../../@fuse/services/splash-screen.service";
import { smartOrderComponent } from "./Smart-Order/smart-order.component";
import { ManageMonthlySubscriptionService } from 'app/main/ManageMonthlySubscription/ManageMonthlySubscription.service';
import { Router } from "@angular/router";
import { RestService } from "../../shared/rest.service";
import { smartOrderComponentCount } from "./Smart-Order-Count/smart-order-count.component";
import { AdditionalSmartOrderComponent } from "./Additional-Smart-Order/additional-smart-order.component";

const trimValidator: ValidatorFn = (control: FormControl) => {
    if (control.value.startsWith(" ")) {
        return {
            trimError: { value: "This field is required" },
        };
    }
};

@Component({
    selector: "ManageMonthlySubscription",
    templateUrl: "./ManageMonthlySubscription.component.html",
    styleUrls: ["./ManageMonthlySubscription.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class ManageMonthlySubscriptionComponent implements OnInit {
    displayedColumns: string[] = ["id", "unitPrice", "bv", "qty", "action"];
    dataSource: any;
    SmartOrderDetail: any;
    CustomerID: any = "";
    panelOpenState = false;
    CustomerBillingInfo: any;
    paymentCardType: any = "1";
    IsCustomerBillingInfo: boolean = false;
    isShowPrimaryCreditCard: any = false;
    isShowsecondaryCreditCard: any = false;
    AutoOrderID: any;
    userData: any;
    ShipMethodID: any = 0;
    step: number;
    customerDetail: object = {};
    SmartOrders: any[] = [];
    shippingForm: any;
    countriesListAll: any;
    stateList: any;
    countriesList: any;
    action: any;
    isShowSmartOrderDetail: boolean = false;
    customerType: any;
    invalid_zip: boolean = false;
    viewButton: boolean = true;
    is_mobileDevice: boolean = false;
    isaddressVerified: boolean = false;
    SavedbillingAddress = {};
    processType:any;
    AutoOrderList:any;
      gridCols: number = 3;
    /**
     * Constructor
     *
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {ManageMonthlySubscriptionService} _projectDashboardService
     */
    constructor(
        private _ManageMonthlySubscriptionService: ManageMonthlySubscriptionService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _matDialog: MatDialog,
        private _formBuilder: FormBuilder,
        private _toastr: ToastrService,
        public _calculateAutoOrder: calculateAutoOrderService,
        public _RgetRegexService: RgetRegexService,
        private _router: Router,
        private _restService: RestService
    ) {
        this.setGridCols(window.innerWidth);
        window.scrollTo(0, 0);
        let params = new URLSearchParams(location.search);
        this.CustomerID = params.get("CustomerID") || "";
        this.AutoOrderID = params.get("AutoOrderID") || "";
        this.userData =
            JSON.parse(localStorage.getItem("LoggedInUserData")) || {};
        if (this.CustomerID === "") {
            this.CustomerID = this.userData.CustomerID
                ? this.userData.CustomerID
                : this.CustomerID;
            this.customerType = this.customerType || this.userData.CustomerType;
        }
        this.action = "edit";
        this._fuseSplashScreenService.show();
        localStorage.removeItem("AllShipMethods");
        localStorage.removeItem("customerDetail");
        localStorage.removeItem("SmartOrderItems");
        localStorage.removeItem("SmartOrderSubItems");
        localStorage.removeItem("SmartOrderDetails");
        localStorage.removeItem("SmartOrderBVTotal");
        localStorage.removeItem("SmartOrdersSubTotal");
        localStorage.removeItem("isModifyCard");
        localStorage.removeItem("isEditDate");
        localStorage.setItem("sameBillAddress", "Yes");
        localStorage.removeItem("billingAddress");
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        window.scrollTo(0, 0);
        if (this.CustomerID) {
            this.GetAutoOrders();
        } else {
            this._router.navigate(["login"]);
        }
        this.is_mobileDevice = /iPhone|iPod|BlackBerry|Windows Phone/i.test(
            navigator.userAgent || navigator.vendor
        )
            ? true
            : false;
    }
    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
      this.setGridCols(event.target.innerWidth);
    }
  
    setGridCols(windowWidth: number) {
      if (windowWidth >= 1200) {
        this.gridCols = 3; 
      } else if (windowWidth >= 768) {
        this.gridCols = 2; 
      } else {
        this.gridCols = 1; 
      }
    }
    GetAutoOrders(afterDeletion?:boolean) {
        this._fuseSplashScreenService.show();
        let req = "";
        if (this.CustomerID) {
            req = "?CustomerID=" + this.CustomerID;
        } else if (this.CustomerID && this.AutoOrderID) {
            req =
                "?CustomerID=" +
                this.CustomerID +
                "&AutoOrderID=" +
                this.AutoOrderID;
        }
        this._ManageMonthlySubscriptionService.GetAutoOrders(req).subscribe(
            (res: any) => {
                if (res.AutoOrderList.length > 0) {
                    this.AutoOrderList = res.AutoOrderList
                    this.SmartOrderDetail =  Object.assign({}, JSON.parse(JSON.stringify(res.AutoOrderList[0])));
                    this.customerDetail = {
                        CompanyName: this.SmartOrderDetail.Company,
                        FirstName: this.SmartOrderDetail.FirstName,
                        LastName: this.SmartOrderDetail.LastName,
                        ShippingBusinessName: this.SmartOrderDetail.Company,
                        Email: this.SmartOrderDetail.Email,
                        Address1: this.SmartOrderDetail.Address1,
                        Address2: this.SmartOrderDetail.Address2,
                        Address3: this.SmartOrderDetail.Address3,
                        CountryCode: this.SmartOrderDetail.Country,
                        State: this.SmartOrderDetail.State,
                        City: this.SmartOrderDetail.City,
                        ShippingZip: this.SmartOrderDetail.Zip,
                        MobilePhone: this.SmartOrderDetail.Phone,
                        ShipMethodID: this.SmartOrderDetail.ShipMethodID,
                        AutoOrderID: this.SmartOrderDetail.AutoOrderID,
                        NextRunDate: this.SmartOrderDetail.NextRunDate,
                        CustomerID: this.SmartOrderDetail.CustomerID,
                    };
                    if (this.SmartOrderDetail.ProcessType==0) {
                        localStorage.setItem("SubscriptionProcessType", JSON.stringify('AutoOrderProcessTy1'));
                        this.processType = JSON.parse(localStorage.getItem("SubscriptionProcessType"));
                    } else {
                        localStorage.setItem("SubscriptionProcessType", JSON.stringify('AutoOrderProcessTy2'));
                        this.processType = JSON.parse(localStorage.getItem("SubscriptionProcessType"));
                    }
                    var SmartOrderDetailItem = _.filter(
                        this.SmartOrderDetail.Details,
                        function (item) {
                            return item.ParentItemCode == null;
                        }
                    );
                    this.dataSource = new MatTableDataSource(
                        SmartOrderDetailItem
                    );
                    localStorage.setItem("City",JSON.stringify(this.SmartOrderDetail.City));
                    localStorage.setItem("State",JSON.stringify(this.SmartOrderDetail.State));
                    localStorage.setItem("Zip",JSON.stringify(this.SmartOrderDetail.Zip));
                    localStorage.setItem("CountryCode",JSON.stringify(this.SmartOrderDetail.Country));
                    localStorage.setItem("customerDetail",JSON.stringify(this.customerDetail));
                    this.createshippingForm(this.customerDetail);
                    this.AutoOrderID = this.SmartOrderDetail.AutoOrderID;
                    this.ShipMethodID = this.SmartOrderDetail.ShipMethodID;
                    this.paymentCardType =
                        this.SmartOrderDetail.PaymentType.toString() == "0"
                            ? "1"
                            : "2";
                    localStorage.setItem(
                        "paymentCardType",
                        JSON.stringify(this.paymentCardType)
                    );
                    console.log("payment from autoorders")
                    var ParentItems = [];
                    if (SmartOrderDetailItem.length == 1) {
                        this.viewButton = false;
                    }
                    for (var i = 0; i < SmartOrderDetailItem.length; i++) {
                        for (
                            var j = 0;
                            j < this.SmartOrderDetail.Details.length;
                            j++
                        ) {
                            if (
                                SmartOrderDetailItem[i].ItemCode ==
                                this.SmartOrderDetail.Details[j].ParentItemCode
                            ) {
                                ParentItems.push(SmartOrderDetailItem[i]);
                                break;
                            }
                        }
                    }

                    ParentItems.forEach((element) => {
                        this.SmartOrderDetail.Details.forEach((ParentItem) => {
                            if (ParentItem.ItemCode == element.ItemCode) {
                                ParentItem.ParentItemCode = element.ItemCode;
                            }
                        });
                    });
                    if (
                        this.SmartOrderDetail.Country == "US" &&
                        this.SmartOrderDetail.State == "CA"
                    ) {
                        this.SmartOrderDetail.Details.forEach((Item) => {
                            if (Item.ItemCode.indexOf("-P65") > 0) {
                                Item.ItemCode = Item.ItemCode.split("-P")[0];
                            }
                            if (
                                Item.ParentItemCode &&
                                Item.ParentItemCode.indexOf("-P65") > 0
                            ) {
                                Item.ParentItemCode =
                                    Item.ParentItemCode.split("-P")[0];
                            }
                        });
                    }

                    this.SmartOrderDetail.Details.forEach((element) => {
                        this.ContinueSmartOrder(element, element.Quantity, 4);
                    });
                    this.GetCustomerBilling();
                    this.isShowSmartOrderDetail = true;
                    this._fuseSplashScreenService.hide();
                    if (res.AutoOrderList.length > 1) {
                        this.openSmartOrderCount(res.AutoOrderList.length);
                    }
                    if (res.AutoOrderList.length != 0 && afterDeletion) {
                        this.AdditionalSmartOrder();
                    }
                } else {
                    this.isShowSmartOrderDetail = false;
                    this.clearData();
                    this.customerDetail = {
                        CompanyName: this.userData.Company,
                        FirstName: this.userData.FirstName,
                        LastName: this.userData.LastName,
                        ShippingBusinessName: "",
                        Email: this.userData.Email,
                        Address1:this.userData.MailAddress1,
                        Address2: "",
                        Address3: "",
                        CountryCode: "US",
                        State: "CA",
                        City: this.userData.MainCity,
                        ShippingZip:this.userData.MailZip,
                        MobilePhone: this.userData.Phone,
                        ShipMethodID: "",
                        AutoOrderID: "",
                        NextRunDate: "",
                        CustomerID: this.CustomerID,
                    };
                    localStorage.setItem("customerDetail",JSON.stringify(this.customerDetail));
                    localStorage.setItem("City", JSON.stringify(""));
                    localStorage.setItem("State", JSON.stringify(""));
                    localStorage.setItem("Zip", JSON.stringify(""));
                    localStorage.setItem("CountryCode", JSON.stringify(""));
                    this._toastr.error("You have not set up a Monthly Subscription yet.");
                    this._fuseSplashScreenService.hide();
                }
            },
            (error) => {
                console.log("error", error);
                this.isShowSmartOrderDetail = false;
                this._toastr.error(error.error.Message, "Error");
                this._fuseSplashScreenService.hide();
            }
        );
    }
   
    showAutoOrderDetails(i)
    { 
        console.log(i);
        this.SmartOrderDetail = Object.assign({}, JSON.parse(JSON.stringify(this.AutoOrderList[i])));
        this.customerDetail = {
            CompanyName: this.SmartOrderDetail.Company,
            FirstName: this.SmartOrderDetail.FirstName,
            LastName: this.SmartOrderDetail.LastName,
            ShippingBusinessName: this.SmartOrderDetail.Company,
            Email: this.SmartOrderDetail.Email,
            Address1: this.SmartOrderDetail.Address1,
            Address2: this.SmartOrderDetail.Address2,
            Address3: this.SmartOrderDetail.Address3,
            CountryCode: this.SmartOrderDetail.Country,
            State: this.SmartOrderDetail.State,
            City: this.SmartOrderDetail.City,
            ShippingZip: this.SmartOrderDetail.Zip,
            MobilePhone: this.SmartOrderDetail.Phone,
            ShipMethodID: this.SmartOrderDetail.ShipMethodID,
            AutoOrderID: this.SmartOrderDetail.AutoOrderID,
            NextRunDate: this.SmartOrderDetail.NextRunDate,
            CustomerID: this.SmartOrderDetail.CustomerID,
        };
        if (this.SmartOrderDetail.ProcessType==0) {
            localStorage.setItem("SubscriptionProcessType", JSON.stringify('AutoOrderProcessTy1'));
            this.processType = JSON.parse(localStorage.getItem("SubscriptionProcessType"));
        } else {
            localStorage.setItem("SubscriptionProcessType", JSON.stringify('AutoOrderProcessTy2'));
            this.processType = JSON.parse(localStorage.getItem("SubscriptionProcessType"));
        }
        var SmartOrderDetailItem = _.filter(
            this.SmartOrderDetail.Details,
            function (item) {
                return item.ParentItemCode == null;
            }
        );
        this.dataSource = new MatTableDataSource(
            SmartOrderDetailItem
        );
        localStorage.setItem(
            "City",
            JSON.stringify(this.SmartOrderDetail.City)
        );
        localStorage.setItem(
            "State",
            JSON.stringify(this.SmartOrderDetail.State)
        );
        localStorage.setItem(
            "Zip",
            JSON.stringify(this.SmartOrderDetail.Zip)
        );
        localStorage.setItem(
            "CountryCode",
            JSON.stringify(this.SmartOrderDetail.Country)
        );
        localStorage.setItem(
            "customerDetail",
            JSON.stringify(this.customerDetail)
        );
        this.createshippingForm(this.customerDetail);
        this.AutoOrderID = this.SmartOrderDetail.AutoOrderID;
        this.ShipMethodID = this.SmartOrderDetail.ShipMethodID;
        this.paymentCardType =
            this.SmartOrderDetail.PaymentType.toString() == "0"
                ? "1"
                : "2";
        localStorage.setItem(
            "paymentCardType",
            JSON.stringify(this.paymentCardType)
        );
        var ParentItems = [];
        if (SmartOrderDetailItem.length == 1) {
            this.viewButton = false;
        }
        for (let i = 0; i < SmartOrderDetailItem.length; i++) {
            for (
                var j = 0;
                j < this.SmartOrderDetail.Details.length;
                j++
            ) {
                if (
                    SmartOrderDetailItem[i].ItemCode ==
                    this.SmartOrderDetail.Details[j].ParentItemCode
                ) {
                    ParentItems.push(SmartOrderDetailItem[i]);
                    break;
                }
            }
        }

        ParentItems.forEach((element) => {
            this.SmartOrderDetail.Details.forEach((ParentItem) => {
                if (ParentItem.ItemCode == element.ItemCode) {
                    ParentItem.ParentItemCode = element.ItemCode;
                }
            });
        });
        if (
            this.SmartOrderDetail.Country == "US" &&
            this.SmartOrderDetail.State == "CA"
        ) {
            this.SmartOrderDetail.Details.forEach((Item) => {
                if (Item.ItemCode.indexOf("-P65") > 0) {
                    Item.ItemCode = Item.ItemCode.split("-P")[0];
                }
                if (
                    Item.ParentItemCode &&
                    Item.ParentItemCode.indexOf("-P65") > 0
                ) {
                    Item.ParentItemCode =
                        Item.ParentItemCode.split("-P")[0];
                }
            });
        }
        localStorage.removeItem("SmartOrderItems");
        this.SmartOrderDetail.Details.forEach((element) => {
            this.ContinueSmartOrder(element, element.Quantity, 4);
        });
        this.isShowSmartOrderDetail = true;
    }
    processChange(value)
    {
        localStorage.setItem("SubscriptionProcessType", JSON.stringify(value));
        let req = {
            AutoOrderID : this.AutoOrderID,
            ProcessType : value == 'AutoOrderProcessTy1' ? 0 : 1,
        };     
        this._ManageMonthlySubscriptionService.UpdateAutoOrderProcessType(req).subscribe(
            (res: any) => {
                if(res.resultField.statusField == 0)
                {
                  this._toastr.success("Your Monthly Subscription updated");
                }
                else{
                    this._toastr.error("Error");
                }
    })
    }
    getCountryList() {
        this._fuseSplashScreenService.show();
        let getStatesRequest = {
            CountryCode: this.SmartOrderDetail.Country || "US",
        };
        this._ManageMonthlySubscriptionService
            .getCountryList(getStatesRequest)
            .subscribe(
                (res: any) => {
                    if (res.countriesField) {
                        this.countriesListAll = res.countriesField;
                        this.stateList = res.regionsField;
                        this.countriesList = _.filter(
                            res.countriesField,
                            function (item) {
                                return (
                                    item.countryCodeField === "US" ||
                                    item.countryCodeField === "CA"
                                );
                            }
                        );

                        this._fuseSplashScreenService.hide();
                    } else {
                        this._fuseSplashScreenService.hide();
                        this._toastr.error("Error");
                    }
                },
                (error) => {
                    this._fuseSplashScreenService.hide();
                    this._toastr.error(error.error.Message, "Error");
                }
            );
    }

    countryChange(countryCode) {
        if (["US"].indexOf(countryCode) == -1) {
            let SmartOrders = JSON.parse(
                localStorage.getItem("SmartOrderItems")
            );
            _.each(SmartOrders, function (item, index) {
                if (
                    item.IsUSOnly === true &&
                    (item.ParentId == item.ItemCode || !item.ParentId)
                ) {
                    this.USOnlyAutoOrderItems.push(item);
                }
            });
            localStorage.setItem("CountryCode", JSON.stringify(countryCode));
            this.getStates(countryCode, 1);
            this.shippingForm.get("Zip").setValue("");
            this.invalid_zip = false;
        } else {
            localStorage.setItem("CountryCode", JSON.stringify(countryCode));
            this.getStates(countryCode, 1);
            this.shippingForm.get("Zip").setValue("");
            this.invalid_zip = false;
        }
    }

    getStates(country, type) {
        this._fuseSplashScreenService.show();
        let getStatesRequest = { CountryCode: country };
        this._ManageMonthlySubscriptionService
            .getCountryList(getStatesRequest)
            .subscribe(
                (res: any) => {
                    if (res.regionsField) {
                        this.stateList = res.regionsField;
                        if (
                            ["US"].indexOf(
                                this.shippingForm.value.CountryCode
                            ) > -1 &&
                            ["NE", "KS"].indexOf(
                                this.shippingForm.value.CountryCode
                            ) > -1
                        ) {
                            this.stateChange(this.shippingForm.value.State, 1);
                        }
                        this._fuseSplashScreenService.hide();
                    } else {
                        this._toastr.error("Error");
                        this._fuseSplashScreenService.hide();
                    }
                },
                (error) => {
                    this._fuseSplashScreenService.hide();
                    this._toastr.error(error.error.Message, "Error");
                }
            );
    }

    stateChange(stateCode, type) {
        if (
            ["US"].indexOf(this.shippingForm.value.CountryCode) > -1 &&
            ["NE", "KS"].indexOf(stateCode) > -1
        ) {
            localStorage.setItem("State", JSON.stringify(stateCode));
            this.checkCalculateOrder();
        } else {
            localStorage.setItem("State", JSON.stringify(stateCode));
            this.checkCalculateOrder();
        }
    }
    checkCalculateOrder() {
        this._calculateAutoOrder.calculate(0);
    }

    createshippingForm(customerDetail) {
        this.shippingForm = this._formBuilder.group({
            CompanyName: [customerDetail.CompanyName],
            FirstName: [
                customerDetail.FirstName,
                [Validators.required, trimValidator],
            ],
            LastName: [
                customerDetail.LastName,
                [Validators.required, trimValidator],
            ],
            Address1: [
                customerDetail.Address1,
                [Validators.required, trimValidator],
            ],
            Address2: [customerDetail.Address2],
            Email: [customerDetail.Email, [Validators.required, trimValidator]],
            Phone: [
                customerDetail.MobilePhone,
                [Validators.required, trimValidator],
            ],
            City: [customerDetail.City, [Validators.required, trimValidator]],
            CountryCode: [
                customerDetail.CountryCode,
                [Validators.required, trimValidator],
            ],
            State: [customerDetail.State, [Validators.required, trimValidator]],
            Zip: [
                customerDetail.ShippingZip,
                [Validators.required, trimValidator],
            ],
        });
    }
    openSmartOrderCount(autoshipLength) {
        const dialogRef = this._matDialog.open(smartOrderComponentCount, {
            width: "650px",
            panelClass: "smart-order",
            data: {
                autoshipLength : autoshipLength
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
           
        });
    }
    addSmartOrder() {
        const dialogRef = this._matDialog.open(smartOrderComponent, {
            width: "650px",
            panelClass: "smart-order",
            data: {},
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result == "Success") {
                this.GetAutoOrders(true);
            }
        });
    }
    AdditionalSmartOrder() {
        const dialogRef = this._matDialog.open(AdditionalSmartOrderComponent, {
            width: "650px",
            panelClass: "smart-order",
            data: {
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result == "Success") {
                this.addSmartOrder();
            }
        });
    }
    GotoNext() {
        this._router.navigate(["Products"]);
    }
    editDate() {
        this._router.navigate(["Calendar"]);
        localStorage.setItem("isEditDate", JSON.stringify(true));
    }
    editPayment() {
        this._router.navigate(["Billing"]);
        localStorage.setItem("isModifyCard", JSON.stringify(true));
    }
    editAddress() {
        this._router.navigate(["Shipping"]);
    }
    editShipping() {
        this._router.navigate(["Shipping"]);
    }

    GetCustomerBilling() {
        this._fuseSplashScreenService.show();
        let req = "?CustomerID=" + this.userData.CustomerID;
        this._ManageMonthlySubscriptionService.GetCustomerBilling(req).subscribe(
            (res: any) => {
                if (res.GetCustomerBillingRes) {
                    this.CustomerBillingInfo = res.GetCustomerBillingRes;
                    this.IsCustomerBillingInfo = true;
                    if (
                        res.GetCustomerBillingRes.primaryCreditCard
                            .CreditCardNumberDisplay
                    ) {
                        if (
                            this.CustomerBillingInfo &&
                            this.CustomerBillingInfo.primaryCreditCard
                                .CreditCardNumberDisplay
                        ) {
                            this.isShowPrimaryCreditCard = this
                                .CustomerBillingInfo.primaryCreditCard
                                .CreditCardNumberDisplay
                                ? true
                                : false;
                        }
                    }
                    if (
                        res.GetCustomerBillingRes.secondaryCreditCard
                            .CreditCardNumberDisplay
                    ) {
                        console.log("In secondary card")
                        if (
                            this.CustomerBillingInfo &&
                            this.CustomerBillingInfo.secondaryCreditCard
                                .CreditCardNumberDisplay
                        ) {
                            this.isShowsecondaryCreditCard = this
                                .CustomerBillingInfo.secondaryCreditCard
                                .CreditCardNumberDisplay
                                ? true
                                : false;
                        }
                    }
                    this._fuseSplashScreenService.hide();
                } else {
                    this._fuseSplashScreenService.hide();
                    this._toastr.error(res.Message, "Error");
                }
                if(this.paymentCardType=='1'){
                    if(!this.CustomerBillingInfo.primaryCreditCard.CreditCardNumberDisplay){
                        this.paymentCardType='2'
                    }
                }
                if(this.paymentCardType=='2'){
                    if(!this.CustomerBillingInfo.secondaryCreditCard.CreditCardNumberDisplay){
                        this.paymentCardType='1'
                    }
                }
                localStorage.setItem(
                    "paymentCardType",
                    JSON.stringify(this.paymentCardType)
                );
                
            },
            (error) => {
                this._fuseSplashScreenService.hide();
                this._toastr.error(error.error.Message, "Error");
            }
        );
       
    }
    closeStep(index: number) {
        this.step = index;
    }

    setStep(index: number) {
        if (index == 2) {
            this.getCountryList();
            this.invalid_zip = false;
        }
        if (index == 1) {
            this.GetCustomerBilling();
            this.invalid_zip = false;
        }
        if (index == 3) {
            this.GetShippingMethods();
            this.invalid_zip = false;
        }
        this.step = index;
    }

    numberOnly(event): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }
    removeItem(parentId) {
        let SmartOrders: any =
            JSON.parse(localStorage.getItem("SmartOrderItems")) || [];
        for (var arrIndex = 0; arrIndex < SmartOrders.length; arrIndex++) {
            if (
                (!SmartOrders[arrIndex].ParentId ||
                    SmartOrders[arrIndex].ParentId === parentId) &&
                SmartOrders[arrIndex].ItemCode == parentId
            ) {
                SmartOrders.splice(arrIndex, 1);
            }
        }

        SmartOrders = _.filter(SmartOrders, function (item) {
            return item.ParentId != parentId;
        });
        var filterResult = _.filter(SmartOrders, function (item) {
            return item.ParentId !== null && item.ParentId !== undefined;
        });
        let SmartOrderSubItems: any;
        SmartOrderSubItems = _.groupBy(filterResult, "ParentId");
        localStorage.setItem("SmartOrderItems", JSON.stringify(SmartOrders));
        localStorage.setItem(
            "SmartOrderSubItems",
            JSON.stringify(SmartOrderSubItems)
        );
        this.CreateAutoOrder();
    }
    ChangeItemQuantity(item, event) {
        if (event.target.value < 1) {
            item.Quantity = 1;
            return;
        }

        this.SmartOrders =
            JSON.parse(localStorage.getItem("SmartOrderItems")) || [];
        _.each(this.SmartOrders, function (smartItem) {
            if (
                item.ItemCode == smartItem.ItemCode ||
                item.ItemCode == smartItem.ParentItemCode
            ) {
                smartItem.Quantity = event.target.value;
            }
        });
        localStorage.setItem(
            "SmartOrderItems",
            JSON.stringify(this.SmartOrders)
        );
        this.CreateAutoOrder();
    }
    ContinueSmartOrder(product, quantity, type) {
        if (parseInt(quantity, 10) <= 0) {
            return false;
        }
        this.SmartOrders =
            JSON.parse(localStorage.getItem("SmartOrderItems")) || [];
        var SmartOrder = _.find(this.SmartOrders, function (item) {
            return (
                item.ItemCode == product.ItemCode &&
                item.ParentId == product.ParentItemCode
            );
        });
        if (!this.isItemRestrcited(product, this.SmartOrders)) {
            if (!SmartOrder) {
                this.SmartOrders.push({
                    ItemCode: product.ItemCode,
                    Description: product.Description,
                    Quantity: quantity ? quantity : 1,
                    Price: product.PriceEach,
                    ShippingPrice: "",
                    TinyImage:
                        "https://purium-api-dev.wsicloud.com/api/NonProfitEnrollment/GetImageData?fileName=" +
                        product.ItemCode +
                        ".png",
                    SmallImage:
                        "https://purium-api-dev.wsicloud.com/api/NonProfitEnrollment/GetImageData?fileName=" +
                        product.ItemCode +
                        ".png",
                    BV: product.CommissionableVolumeEach,
                    CV: product.CommissionableVolume,
                    TaxablePrice: "",
                    LongDetail: "",
                    LongDetail2: "",
                    LongDetail3: "",
                    LongDetail4: "",
                    ParentId: product.ParentItemCode,
                    IsUSOnly: product.IsUSOnly ? product.IsUSOnly : false,
                });
                localStorage.setItem(
                    "SmartOrderItems",
                    JSON.stringify(this.SmartOrders)
                );
            } else {
                SmartOrder.Quantity = SmartOrder.Quantity + 1;
                _.find(this.SmartOrders, function (item) {
                    if (item.ItemCode == product.ItemCode) {
                        item.Quantity = SmartOrder.Quantity;
                    }
                });
                localStorage.setItem(
                    "SmartOrderItems",
                    JSON.stringify(this.SmartOrders)
                );
            }
            this._calculateAutoOrder.TotalItemLength(this.SmartOrders.length);
            let SmartOrdersSubTotal = 0;
            let SmartOrderBVTotal = 0;
            this.SmartOrders = JSON.parse(
                localStorage.getItem("SmartOrderItems")
            );
            _.each(this.SmartOrders, function (item) {
                SmartOrdersSubTotal =
                    SmartOrdersSubTotal + item.Quantity * item.Price;
                SmartOrderBVTotal = SmartOrderBVTotal + item.Quantity * item.BV;
                localStorage.setItem(
                    "SmartOrdersSubTotal",
                    JSON.stringify(SmartOrdersSubTotal)
                );
                localStorage.setItem(
                    "SmartOrderBVTotal",
                    JSON.stringify(SmartOrderBVTotal)
                );
            });

            this._calculateAutoOrder.SetSmartOrdersSubTotal(
                SmartOrdersSubTotal
            );
            this._calculateAutoOrder.SetSmartOrderBVTotal(SmartOrderBVTotal);
            var filterResult = _.filter(this.SmartOrders, function (item) {
                return item.ParentId !== undefined && item.ParentId != null;
            });
            let SmartOrderSubItems: any;
            SmartOrderSubItems = _.groupBy(filterResult, "ParentId");
            localStorage.setItem(
                "SmartOrderSubItems",
                JSON.stringify(SmartOrderSubItems)
            );
            localStorage.setItem(
                "SmartOrderBVTotal",
                JSON.stringify(SmartOrderBVTotal)
            );
            this._calculateAutoOrder.SetSmartOrderItems(this.SmartOrders);
            this._calculateAutoOrder.SetSmartOrderSubItems(SmartOrderSubItems);
        } else {
            if (!product.ParentId || product.ParentId == product.ItemCode) {
                this._toastr.error(
                    "You can buy only " +
                        product.RestrictQty +
                        " quantity of this item."
                );
            }
        }
    }
    isItemRestrcited(product, SmartOrders) {
        var isRestricted = false;
        var filterItem = { Quantity: "" };
        filterItem = _.find(SmartOrders, function (item) {
            return item.ItemCode == product.ItemCode;
        });
        if (
            product.RestrictQty &&
            product.RestrictQty > 0 &&
            filterItem &&
            filterItem.Quantity >= product.RestrictQty
        ) {
            isRestricted = true;
        }
        return isRestricted;
    }
    CreateAutoOrder() {
        let SmartOrderItems = JSON.parse(
            localStorage.getItem("SmartOrderItems")
        );
        if (this.shippingForm.invalid) {
            this.shippingForm.markAllAsTouched();
            this._toastr.error("Please Shipping Address", "Error");
            return;
        }
        this._fuseSplashScreenService.show();
        //let AutoShipStartDate =  JSON.parse(localStorage.getItem("AutoShipStartDate"));
        let shippingForm = this.shippingForm
            ? this.shippingForm.getRawValue()
            : {};
        let customerDetail = JSON.parse(localStorage.getItem("customerDetail"));
        let CustomShipMethods = JSON.parse(
            localStorage.getItem("CustomShipMethods")
        )
            ? JSON.parse(localStorage.getItem("CustomShipMethods"))
            : [{ ShipMethodID: 0 }];
        var itemDetail = [];

        var ParentItemCode;
        _.each(SmartOrderItems, function (obj) {
            if (obj.ParentId && obj.ParentId !== obj.ItemCode) {
                ParentItemCode = obj.ParentId.toString();
            } else {
                ParentItemCode = "";
            }
            itemDetail.push({
                ItemCode: obj.ItemCode,
                Quantity: obj.Quantity,
                ParentItemCode: ParentItemCode,
            });
        });
        let req = {
            AutoOrderRequest: {
                AutoOrderCartID: "",
                AutoShipStartDate: customerDetail.NextRunDate,
                AutoshipType: 0,
                BeginMonth: 0,
                Details: itemDetail ? itemDetail : [],
                ShipMethodID: CustomShipMethods[0].ShipMethodID
                    ? CustomShipMethods[0].ShipMethodID
                    : this.ShipMethodID,
            },
            Company: shippingForm.CompanyName ? shippingForm.CompanyName : "",
            CustomerType: this.userData.CustomerType
                ? this.userData.CustomerType
                : 0,
            Email: shippingForm.Email
                ? shippingForm.Email
                : customerDetail.Email,
            ShippingAddress: {
                FirstName: shippingForm.FirstName
                    ? shippingForm.FirstName
                    : customerDetail.FirstName,
                LastName: shippingForm.LastName
                    ? shippingForm.LastName
                    : customerDetail.LastName,
                BusinessName: "",
                Address1: shippingForm.Address1
                    ? shippingForm.Address1
                    : customerDetail.Address1,
                Address2: shippingForm.Address2
                    ? shippingForm.Address2
                    : customerDetail.Address2,
                Address3: "",
                City: shippingForm.City
                    ? shippingForm.City
                    : customerDetail.City,
                CountryCode: shippingForm.CountryCode
                    ? shippingForm.CountryCode
                    : customerDetail.CountryCode,
                State: shippingForm.State
                    ? shippingForm.State
                    : customerDetail.State,
                Zip: shippingForm.Zip
                    ? shippingForm.Zip
                    : customerDetail.ShippingZip,
            },
            FirstName: shippingForm.FirstName
                ? shippingForm.FirstName
                : customerDetail.FirstName,
            LastName: shippingForm.LastName
                ? shippingForm.LastName
                : customerDetail.LastName,
            Phone1: shippingForm.Phone
                ? shippingForm.Phone
                : customerDetail.MobilePhone,
            CustomerId: this.CustomerID ? this.CustomerID : "",
            AutoOrderID: this.AutoOrderID ? this.AutoOrderID : 0,
            PaymentType:
                this.paymentCardType == "1"
                    ? "PrimaryCreditCard"
                    : "SecondaryCreditCard",
        };
        this._ManageMonthlySubscriptionService.CreateAutoOrder(req).subscribe(
            (res: any) => {
                if (res) {
                    if (res.AutoOrderID) {
                        this.setStep(4);
                        this._toastr.success("Your Monthly Subscription updated");
                        this.clearData();
                        this.GetAutoOrders();
                    } else {
                        this._toastr.error(res.Result.errorsField);
                        this._fuseSplashScreenService.hide();
                    }
                    this._fuseSplashScreenService.hide();
                } else {
                    this._fuseSplashScreenService.hide();
                    this._toastr.error("Something went wrong please try again");
                }
            },
            (error) => {
                this._fuseSplashScreenService.hide();
                this._toastr.error(error.error.Message, "Error");
                this._toastr.error("Something went wrong please try again");
            }
        );
    }
    GetShippingMethods() {
        this._calculateAutoOrder.calculate(
            this.ShipMethodID ? this.ShipMethodID : 0
        );
    }
    changeshipMethodId(ShipMethodID) {
        this.ShipMethodID = ShipMethodID;
        this._calculateAutoOrder.calculate(
            this.ShipMethodID ? this.ShipMethodID : 0
        );
    }

    regexValidateZip(event) {
        let shippingForm = this.shippingForm
            ? this.shippingForm.getRawValue()
            : {};
        var regxExp = this._RgetRegexService.getRegex(shippingForm.CountryCode);
        var filter = new RegExp(regxExp);
        if (filter.test(event.target.value)) {
            this.invalid_zip = false;
        } else if (event.target.value && event.target.value.indexOf(" ") != 0) {
            this.invalid_zip = true;
        } else {
            this.invalid_zip = false;
        }
    }
    getSmartOrderShipmethod() {
        if (this.step == 3) {
            var AllShipMethods = JSON.parse(
                localStorage.getItem("AllShipMethods")
            );
            var SmartOrderDetails = JSON.parse(
                localStorage.getItem("SmartOrderDetails")
            );
            var ShipMethodID = this.ShipMethodID;
            var description = _.filter(AllShipMethods, function (ShipMethods) {
                if (ShipMethods.shipMethodIDField == ShipMethodID) {
                    return ShipMethods.descriptionField;
                }
            });
            return (
                description[0].descriptionField +
                "- $" +
                SmartOrderDetails.ShippingTotal
            );
        }
    }

    verifyAddress() {
        if (this.shippingForm.valid) {
            this._fuseSplashScreenService.show();
            const shippingform = this.shippingForm.getRawValue();
            const shippingAddress = {
                Address1: shippingform.Address1,
                City: shippingform.City,
                State: shippingform.State,
                CountryCode: shippingform.CountryCode,
                Zip: shippingform.Zip,
            };

            this._restService.VerifyAddress(shippingAddress).subscribe(
                (res: any) => {
                    if (res.Status === 1 && res.Data) {
                        this.isaddressVerified = true;
                        this._toastr.success(
                            "Address verified Successfully",
                            "Success"
                        );
                        this._fuseSplashScreenService.hide();
                    } else {
                        this.isaddressVerified = false;
                        this._toastr.error("Invalid Address", "Error");
                        this._fuseSplashScreenService.hide();
                    }
                },
                (error) => {
                    console.log(error);
                    this.isaddressVerified = false;
                    this._fuseSplashScreenService.hide();
                    this._toastr.error("Invalid Address", "Error");
                }
            );
        } else {
            this.shippingForm.markAllAsTouched();
            this._toastr.error("Please Add Shipping Info");
        }
    }

    clearData() {
        localStorage.removeItem("SmartOrderItems");
        localStorage.removeItem("SmartOrderDetails");
        localStorage.removeItem("SmartOrderBVTotal");
        localStorage.removeItem("SmartOrdersSubTotal");
        localStorage.removeItem("paymentInfo");
        localStorage.removeItem("billingAddress");
        localStorage.removeItem("shippingAddress");
        localStorage.removeItem("SmartOrderSubItems");
        localStorage.removeItem("AutoShipStartDate");
        localStorage.removeItem("CustomShipMethods");
        localStorage.removeItem("orderDetails");
        localStorage.removeItem("CustomShipMethods");
        localStorage.removeItem("paymentCardType");
        localStorage.removeItem("DefaultCountryCode");
        localStorage.removeItem("isModifyCard");
        localStorage.removeItem("isEditDate");
        localStorage.removeItem("City");
        localStorage.removeItem("State");

        localStorage.removeItem("Zip");
        localStorage.removeItem("CountryCode");

        this._calculateAutoOrder.SetSmartOrderItems([]);
        this._calculateAutoOrder.SetSmartOrderSubItems([]);
        this._calculateAutoOrder.TotalItemLength(0);
    }
    deletePayment() {
        let request = {
            CustomerID: this.CustomerID,
            CardType: this.paymentCardType == '1' ? "Primary" : 'Secondary'
        };
        this._ManageMonthlySubscriptionService.DeleteCard(request).subscribe((res)=>{
            console.log(res)
            this.paymentCardType=='1'?this.paymentCardType='2':this.paymentCardType='1'
        },(err)=>{
            console.log(err)
        })
        this.GetCustomerBilling();
        localStorage.setItem(
            "paymentCardType",
            JSON.stringify(this.paymentCardType)
        );
        
    }
}
