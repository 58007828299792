import { NgModule } from '@angular/core';


import { Error404Module } from 'app/main/errors/404/error-404.module';
import { Error500Module } from 'app/main/errors/500/error-500.module';
import {ProductsModule} from 'app/main/Products/Products.module';
import {CheckoutModule} from 'app/main/Checkout/Checkout.module';
import {CompleteModule} from 'app/main/Complete/Complete.module';
import {AutoOrderSummaryModule} from 'app/main/AutoOrderSummary/AutoOrderSummary.module'
import { from } from 'rxjs';
import { CalendarModule } from './Calendar/Calendar.module';
import {ShippingModule} from './Shipping/Shipping.module';
import { BillingModule } from './Billing/Billing.module';
import { ManageMonthlySubscriptionModule } from './ManageMonthlySubscription/ManageMonthlySubscription.module';
@NgModule({
    imports: [
        // Dashboard
        ManageMonthlySubscriptionModule,
        AutoOrderSummaryModule,
        ProductsModule,
        CheckoutModule,
        CalendarModule,
        // Errors
        Error404Module,
        Error500Module,
        CompleteModule,
        ShippingModule,
        BillingModule
    ],
    declarations: []
})
export class PagesModule
{

}
