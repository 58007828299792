import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { ToastrService } from 'ngx-toastr';
import { stepperService } from 'app/shared/stepper.service';
import { calculateAutoOrderService } from '../../shared/calculateAutoOrder.service';
import { RgetRegexService } from '../../shared/getRegex.service';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { FormBuilder, FormGroup, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { CheckoutService } from 'app/main/Checkout/Checkout.service';
import * as _ from 'underscore';
const trimValidator: ValidatorFn = (control: FormControl) => {
    if (control.value.startsWith(' ')) {
        return {
            'trimError': { value: 'This field is required' }
        };
    }
};
@Component({
    selector: 'Billing',
    templateUrl: './Billing.component.html',
    styleUrls: ['./Billing.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class BillingComponent implements OnInit {
    paymentForm: FormGroup;
    customerDetail: any = {};
    action: string;
    CustomerBillingInfo: any;
    isShowPrimaryCreditCard: any = false;
    isShowsecondaryCreditCard: any;
    IsCustomerBillingInfo: boolean = false;
    countriesList: any[] = [];
    yearList: any[] = [];
    stateList1: any[] = [];
    stateList2: any[] = [];
    countriesListAll: any[] = [];
    billingAddresstForm: FormGroup;
    BillAddress: string = 'Yes';
    currentYear = new Date().getFullYear();
    paymentCardType: any;
    isPrimaryCreditInfo: boolean = false;
    isSecondaryCreditInfo: any = false;
    isPaymentFieldShow: any = false;
    LoggedInUserData: any;
    isModifyCard: boolean = false;
    invalid_zip2: boolean = false;
    shippingAddress: any = {};
    SavedbillingAddress: any = {};

    constructor(
        private _toastr: ToastrService,
        private _CheckoutService: CheckoutService,
        private _formBuilder: FormBuilder,
        private _router: Router,
        private _stepperService: stepperService,
        public _RgetRegexService: RgetRegexService,
        private _fuseSplashScreenService: FuseSplashScreenService,) {

        this._stepperService.SetStepperPage('Billing');
        this.BillAddress = localStorage.getItem('sameBillAddress') ? localStorage.getItem('sameBillAddress') : 'Yes';
        this.customerDetail = JSON.parse(localStorage.getItem('customerDetail')) ? JSON.parse(localStorage.getItem('customerDetail')) : {};
        this.LoggedInUserData = JSON.parse(localStorage.getItem('LoggedInUserData'));
        this.shippingAddress = JSON.parse(localStorage.getItem('shippingAddress'));
        this.SavedbillingAddress = localStorage.getItem("billingAddress") ? JSON.parse(localStorage.getItem("billingAddress")) : null;
        this.action = this.customerDetail ? 'edit' : "";
        if (JSON.parse(localStorage.getItem('isModifyCard'))) {
            this.isModifyCard = true;
        } else {
            this.isModifyCard = false;
        }
        for (let i = 0; i < 20; i++) {
            this.yearList.push({ key: (this.currentYear + i), value: (this.currentYear + i) });
        }

        this.billingAddresstForm = this._formBuilder.group({
            FirstName: [this.action == 'edit' ? "" : '', [Validators.required, trimValidator]],
            LastName: [this.action == 'edit' ? "" : '', [Validators.required, trimValidator]],
            Address1: [this.action == 'edit' ? "" : '', [Validators.required, trimValidator]],
            Address2: [this.action == 'edit' ? "" : ''],
            City: [this.action == 'edit' ? "" : '', [Validators.required, trimValidator]],
            CountryCode: [this.action == 'edit' ? "" : 'US', [Validators.required, trimValidator]],
            State: [this.action == 'edit' ? "" : 'CA', [Validators.required, trimValidator]],
            Zip: [this.action == 'edit' ? "" : '', [Validators.required, trimValidator]]
        });
        this.paymentForm = this._formBuilder.group({
            CardNumber: [this.action == 'edit' ? "" : '', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.maxLength(16),]],
            NameOnCard: [this.action == 'edit' ? "" : '', [Validators.required]],
            CvcCode: [this.action == 'edit' ? "" : '', [Validators.required, Validators.pattern(/^-?(0|[0-9]\d*)?$/), Validators.maxLength(4), Validators.minLength(3)]],
            ExpirationMonth: [this.action == 'edit' ? "" : '', [Validators.required]],
            ExpirationYear: [this.action == 'edit' ? "" : '', [Validators.required]]
        });
        if (this.LoggedInUserData.CustomerID) {
            this.GetCustomerBilling();
        } else {
            this.paymentCardType = '1';
            this.IsCustomerBillingInfo = true;
            this.isPrimaryCreditInfo = true;
        }
    }

    ngOnInit() {
        $("#container-3").scrollTop(0);
        this.getCountryList();

    }

    getCountryList() {
        this._fuseSplashScreenService.show();
        let getStatesRequest = { CountryCode: (this.SavedbillingAddress && this.SavedbillingAddress.CountryCode) ? this.SavedbillingAddress.CountryCode : 'US' };
        this._CheckoutService.getCountryList(getStatesRequest).subscribe((res: any) => {
            if (res.countriesField) {
                if (res.regionsField) {
                    this.stateList2 = res.regionsField;
                    //let form = this.billingAddresstForm.getRawValue();
                    let State = res.regionsField[0].regionCodeField;
                    this.billingAddresstForm.get('State').setValue(State);
                }
                this.countriesListAll = res.countriesField;
                this.countriesList = _.filter(res.countriesField, function (item) {
                    return (item.countryCodeField === 'US' || item.countryCodeField === 'CA');
                });
                if (this.isModifyCard) {
                    //this.countryChange('US');
                } else {
                    //   this.countryChange(this.shippingForm.value.CountryCode, 1);
                }

                this.billingAddress(localStorage.getItem('sameBillAddress'));
                this._fuseSplashScreenService.hide();
            } else {
                this._fuseSplashScreenService.hide();
                this._toastr.error('Error');
            }
        }, error => {
            this._fuseSplashScreenService.hide();
            this._toastr.error(error, 'Error');
        });
    }
    countryChange(countryCode) {
        this.getStates(countryCode);
        this.invalid_zip2 = false;
    }

    getStates(country) {
        this._fuseSplashScreenService.show();
        let getStatesRequest = { CountryCode: country };
        this._CheckoutService.GetCountryRegions(getStatesRequest).subscribe((res: any) => {
            if (res.regionsField) {

                this.stateList2 = res.regionsField;
                //let form = this.billingAddresstForm.getRawValue();
                let State = res.regionsField[0].regionCodeField;
                this.billingAddresstForm.get('State').setValue(State);
                this._fuseSplashScreenService.hide();

            } else {
                this._toastr.error('Error');
                this._fuseSplashScreenService.hide();
            }
        }, error => {
            this._fuseSplashScreenService.hide();
            this._toastr.error(error, 'Error');
        });
    }

    billingAddress(isBillAddress) {
        localStorage.setItem('sameBillAddress', (isBillAddress == 'No' ? 'No' : 'Yes'));
        if (isBillAddress == 'No') {
            let billingAddress = {
                FirstName: '',
                LastName: '',
                Address1: '',
                Address2: '',
                City: '',
                CountryCode: '',
                State: '',
                Zip: ''
            };
            if (this.SavedbillingAddress) {
                billingAddress = {
                    FirstName: this.SavedbillingAddress.FirstName ? this.SavedbillingAddress.FirstName : '',
                    LastName: this.SavedbillingAddress.LastName ? this.SavedbillingAddress.LastName : '',
                    Address1: this.SavedbillingAddress.Address1 ? this.SavedbillingAddress.Address1 : '',
                    Address2: this.SavedbillingAddress.Address2 ? this.SavedbillingAddress.Address2 : '',
                    City: this.SavedbillingAddress.City ? this.SavedbillingAddress.City : '',
                    CountryCode: this.SavedbillingAddress.CountryCode ? this.SavedbillingAddress.CountryCode : 'US',
                    State: this.SavedbillingAddress.State ? this.SavedbillingAddress.State : 'CA',
                    Zip: this.SavedbillingAddress.Zip ? this.SavedbillingAddress.Zip : ''
                }
            }
            this.billingAddresstForm.get('FirstName').setValue(billingAddress.FirstName);
            this.billingAddresstForm.get('LastName').setValue(billingAddress.LastName);
            this.billingAddresstForm.get('Address1').setValue(billingAddress.Address1);
            this.billingAddresstForm.get('Address2').setValue(billingAddress.Address2);
            this.billingAddresstForm.get('City').setValue(billingAddress.City);
            this.billingAddresstForm.get('CountryCode').setValue(billingAddress.CountryCode);
            this.billingAddresstForm.get('State').setValue(billingAddress.State);
            this.billingAddresstForm.get('Zip').setValue(billingAddress.Zip);
            this.invalid_zip2 = false;
            if (this.shippingAddress.CountryCode != 'US') {
                this.getStates('US');
            }
        } else {
            this.SavedbillingAddress = null;
            this.BillAddress = 'Yes'
            this.setAddress();
        }

    }
    setAddress() {

        if (this.BillAddress == 'Yes') {
            let form = this.shippingAddress;
            let FirstName = form.FirstName || "";
            this.billingAddresstForm.get('FirstName').setValue(FirstName);
            let LastName = form.LastName || "";
            this.billingAddresstForm.get('LastName').setValue(LastName);
            let Address1 = form.Address1 || "";
            this.billingAddresstForm.get('Address1').setValue(Address1);
            let Address2 = form.Address2 || "";
            this.billingAddresstForm.get('Address2').setValue(Address2);
            let City = form.City || "";
            this.billingAddresstForm.get('City').setValue(City);
            let CountryCode = form.CountryCode || "US";
            this.billingAddresstForm.get('CountryCode').setValue(CountryCode);
            let State = form.State || "CA";
            this.billingAddresstForm.get('State').setValue(State);
            let Zip = form.Zip || "";
            this.billingAddresstForm.get('Zip').setValue(Zip);
        }
    }

    addCard(type, action, billingInfo) {
        var isMasked = billingInfo.CreditCardNumberDisplay ? (billingInfo.CreditCardNumberDisplay).indexOf('*') > -1 : false;
        if (type == 1 && action == "edit") {
            this.isPrimaryCreditInfo = true;
            this.paymentCardType = '1';
            this.paymentForm.get('CardNumber').setValue(action == 'edit' ? (isMasked ? "" : billingInfo.CreditCardNumberDisplay) : "");
            this.paymentForm.get('CvcCode').setValue("");
            this.paymentForm.get('NameOnCard').setValue(action == 'edit' ? billingInfo.BillingName : "");
            this.paymentForm.get('ExpirationMonth').setValue(action == 'edit' ? billingInfo.ExpirationMonth : "");
            this.paymentForm.get('ExpirationYear').setValue(action == 'edit' ? billingInfo.ExpirationYear : "");
            if (this.isModifyCard) {
                this.billingAddresstForm.get('FirstName').setValue(billingInfo.BillingName);

                this.billingAddresstForm.get('LastName').setValue("");

                this.billingAddresstForm.get('Address1').setValue(billingInfo.BillingAddress);

                this.billingAddresstForm.get('Address2').setValue(billingInfo.BillingAddress2);

                this.billingAddresstForm.get('City').setValue(billingInfo.BillingCity);

                this.billingAddresstForm.get('CountryCode').setValue(billingInfo.BillingCountry);

                this.billingAddresstForm.get('State').setValue(billingInfo.BillingState);

                this.billingAddresstForm.get('Zip').setValue(billingInfo.BillingZip);
            }

            this.isPaymentFieldShow = true;

        } else if (type == 2 && action == "edit") {
            this.isSecondaryCreditInfo = true;
            this.paymentCardType = '2';
            this.paymentForm.get('CardNumber').setValue(action == 'edit' ? (isMasked ? "" : billingInfo.CreditCardNumberDisplay) : "");
            this.paymentForm.get('CvcCode').setValue("");
            this.paymentForm.get('NameOnCard').setValue(action == 'edit' ? billingInfo.BillingName : "");
            this.paymentForm.get('ExpirationMonth').setValue(action == 'edit' ? billingInfo.ExpirationMonth : "");
            this.paymentForm.get('ExpirationYear').setValue(action == 'edit' ? billingInfo.ExpirationYear : "");
            if (this.isModifyCard) {
                this.billingAddresstForm.get('FirstName').setValue(billingInfo.BillingName);

                this.billingAddresstForm.get('LastName').setValue("");

                this.billingAddresstForm.get('Address1').setValue(billingInfo.BillingAddress);

                this.billingAddresstForm.get('Address2').setValue(billingInfo.BillingAddress2);

                this.billingAddresstForm.get('City').setValue(billingInfo.BillingCity);

                this.billingAddresstForm.get('CountryCode').setValue(billingInfo.BillingCountry);

                this.billingAddresstForm.get('State').setValue(billingInfo.BillingState);

                this.billingAddresstForm.get('Zip').setValue(billingInfo.BillingZip);
            }
            this.isPaymentFieldShow = true;

        } else if (type == 1 && action == "add") {
            this.isPrimaryCreditInfo = true;
            this.paymentCardType = '1';
            this.paymentForm.get('CardNumber').setValue("");
            this.paymentForm.get('CvcCode').setValue("");
            this.paymentForm.get('NameOnCard').setValue(action == 'edit' ? billingInfo.BillingName : "");
            this.paymentForm.get('ExpirationMonth').setValue("");
            this.paymentForm.get('ExpirationYear').setValue("");
            this.isPaymentFieldShow = true;

        } else if (type == 2 && action == "add") {
            this.isSecondaryCreditInfo = true;
            this.paymentCardType = '2';
            this.paymentForm.get('CardNumber').setValue("");
            this.paymentForm.get('CvcCode').setValue("");
            this.paymentForm.get('NameOnCard').setValue(action == 'edit' ? billingInfo.BillingName : "");
            this.paymentForm.get('ExpirationMonth').setValue("");
            this.paymentForm.get('ExpirationYear').setValue("");
            this.isPaymentFieldShow = true;

        }
    }

    GetCustomerBilling() {
        this._fuseSplashScreenService.show();
        let req = '?CustomerID=' + this.LoggedInUserData.CustomerID;
        this._CheckoutService.GetCustomerBilling(req).subscribe((res: any) => {
            if (res.GetCustomerBillingRes) {
                this.CustomerBillingInfo = res.GetCustomerBillingRes;
                this.IsCustomerBillingInfo = true;
                if (res.GetCustomerBillingRes.primaryCreditCard && res.GetCustomerBillingRes.primaryCreditCard.CreditCardNumberDisplay) {
                    if (this.CustomerBillingInfo && this.CustomerBillingInfo.primaryCreditCard.CreditCardNumberDisplay) {
                        this.isShowPrimaryCreditCard = this.CustomerBillingInfo.primaryCreditCard.CreditCardNumberDisplay ? true : false;
                    }

                }
                if (res.GetCustomerBillingRes.secondaryCreditCard && res.GetCustomerBillingRes.secondaryCreditCard.CreditCardNumberDisplay) {
                    if (this.CustomerBillingInfo && this.CustomerBillingInfo.secondaryCreditCard.CreditCardNumberDisplay) {
                        this.isShowsecondaryCreditCard = this.CustomerBillingInfo.secondaryCreditCard.CreditCardNumberDisplay ? true : false;
                    }
                }
                if (this.isShowPrimaryCreditCard || !this.isShowsecondaryCreditCard) {
                    this.paymentCardType = '1';
                    this.ChangeCardType('1', this.CustomerBillingInfo.primaryCreditCard);
                } else if (this.isShowsecondaryCreditCard) {
                    this.paymentCardType = '2';
                    this.ChangeCardType('2', this.CustomerBillingInfo.secondaryCreditCard);
                }

                this._fuseSplashScreenService.hide();
            }
            else {
                this._fuseSplashScreenService.hide();
                this._toastr.error(res.Message, 'Error');
            }

        }, error => {
            this._fuseSplashScreenService.hide();
            this._toastr.error(error, 'Error');
        });
    }
    SavetAccountCreditCard() {
        if (!this.paymentForm.valid) {
            this.paymentForm.markAllAsTouched();
            return
        }

        if (!this.isModifyCard) {
            if (this.billingAddresstForm.invalid) {
                this._toastr.error('Please add Billing Address', 'Error');
                return;
            };
            if (this.BillAddress == 'Yes' && this.shippingAddress.valid) {
                this.setAddress();
            }
            var paymentform = this.paymentForm.getRawValue();
            var billingform = this.billingAddresstForm.getRawValue();
        } else {
            if (!this.billingAddresstForm.valid) {
                this.billingAddresstForm.markAllAsTouched();
                return
            }
            var paymentform = this.paymentForm.getRawValue();
            var billingform = this.billingAddresstForm.getRawValue();
        }

        this._fuseSplashScreenService.show();
        let req = {
            CustomerID: this.LoggedInUserData.CustomerID ? this.LoggedInUserData.CustomerID : "",
            Payment: {
                Amount: 0,
                BillingAddress: {
                    FirstName: billingform.FirstName,
                    LastName: billingform.LastName,
                    BusinessName: "",
                    Address1: billingform.Address1,
                    Address2: billingform.Address2 ? billingform.Address2 : "",
                    Address3: "",
                    City: billingform.City,
                    CountryCode: billingform.CountryCode,
                    State: billingform.State,
                    Zip: billingform.Zip,
                },
                CardNumber: paymentform.CardNumber,
                CardType: this.paymentCardType == '1' ? "Primary" : 'Secondary',
                CurrencyCode: "usd",
                ExpirationMonth: paymentform.ExpirationMonth,
                ExpirationYear: paymentform.ExpirationYear,
                NameOnCard: paymentform.NameOnCard,
                SavePayment: true,
                CvcCode: paymentform.CvcCode,
                SelectedCc: 1
            },
            BillingAddressPreference: (this.BillAddress == 'Yes' && !this.isModifyCard) ? 0 : 1
        };
        this._CheckoutService.SetCustomerBilling(req).subscribe((res: any) => {
            if (res.resultField.errorsField.length == 0) {
                this._fuseSplashScreenService.hide();
                this._toastr.success('Your Payment Card Saved');
                this.cancelCard();
                this.GetCustomerBilling();
                if (JSON.parse(localStorage.getItem('isModifyCard'))) {
                    this._router.navigate(['ManageMonthlySubscription']);
                }
            }
            else {
                this._fuseSplashScreenService.hide();
                this._toastr.error(res.resultField.errorsField[0], 'Error');
            }

        }, error => {
            this._fuseSplashScreenService.hide();
            this._toastr.error(error, 'Error');
        });
    }

    cancelCard() {
        this.isPaymentFieldShow = false;
        this.paymentForm.get('CardNumber').setValue("");
        this.paymentForm.get('CvcCode').setValue("");
        this.paymentForm.get('NameOnCard').setValue("");
        this.paymentForm.get('ExpirationMonth').setValue("");
        this.paymentForm.get('ExpirationYear').setValue("");
        if (JSON.parse(localStorage.getItem('isModifyCard'))) {
            this._router.navigate(['ManageMonthlySubscription']);
        }
    }
    ChangeCardType(type, billingInfo) {
        this.isPaymentFieldShow = false;
        if (type == 1 && billingInfo) {
            this.paymentForm.get('CardNumber').setValue(billingInfo.CreditCardNumberDisplay);
            this.paymentForm.get('CvcCode').setValue("");
            this.paymentForm.get('NameOnCard').setValue(billingInfo.BillingName);
            this.paymentForm.get('ExpirationMonth').setValue(billingInfo.ExpirationMonth);
            this.paymentForm.get('ExpirationYear').setValue(billingInfo.ExpirationYear);
        } else if (type == 2 && billingInfo) {
            this.paymentForm.get('CardNumber').setValue(billingInfo.CreditCardNumberDisplay);
            this.paymentForm.get('CvcCode').setValue("");
            this.paymentForm.get('NameOnCard').setValue(billingInfo.BillingName);
            this.paymentForm.get('ExpirationMonth').setValue(billingInfo.ExpirationMonth);
            this.paymentForm.get('ExpirationYear').setValue(billingInfo.ExpirationYear);
        } else {
            this.paymentForm.get('CardNumber').setValue("");
            this.paymentForm.get('CvcCode').setValue("");
            this.paymentForm.get('NameOnCard').setValue("");
            this.paymentForm.get('ExpirationMonth').setValue("");
            this.paymentForm.get('ExpirationYear').setValue("");
        }
    }

    GotoBack() {
        this._router.navigate(['Shipping']);
    }

    GotoNext() {
        if (this.BillAddress != 'Yes') {
            if (!this.billingAddresstForm.valid) {
                this._toastr.error('Please Add Billing Info');
                return;
            }
            let form = this.billingAddresstForm ? this.billingAddresstForm.getRawValue() : {};
            var regxExp = this._RgetRegexService.getRegex(form.CountryCode);
            var filter = new RegExp(regxExp);
            if (filter.test(form.Zip)) {
                this.invalid_zip2 = false;
            } else if (form.Zip && (form.Zip.indexOf(' ') != 0)) {
                this.invalid_zip2 = true;
                this._toastr.error('Invalid Zip Code');
                return;
            }
        }
        if ((this.isShowPrimaryCreditCard && this.paymentCardType) || (this.isShowsecondaryCreditCard && this.paymentCardType)) {
            this.setAddress();
            let billingform = this.billingAddresstForm.getRawValue();
            let billingAddress = {
                FirstName: billingform.FirstName,
                LastName: billingform.LastName,
                Address1: billingform.Address1,
                Address2: billingform.Address2,
                City: billingform.City,
                CountryCode: billingform.CountryCode,
                State: billingform.State,
                Zip: billingform.Zip,
            }
            let paymentForm = this.paymentForm.getRawValue();
            let paymentInfo = {
                CardNumber: paymentForm.CardNumber,
                CardType: this.paymentCardType ? this.paymentCardType : 1,
                CurrencyCode: "usd",
                ExpirationMonth: paymentForm.ExpirationMonth,
                ExpirationYear: paymentForm.ExpirationYear,
                NameOnCard: paymentForm.NameOnCard,
                SavePayment: true,
                CvcCode: paymentForm.CvcCode,
            }

            localStorage.setItem("paymentInfo", JSON.stringify(paymentInfo));
            localStorage.setItem("billingAddress", JSON.stringify(billingAddress));
            localStorage.setItem("paymentCardType", JSON.stringify(this.paymentCardType));

            this._router.navigate(['Checkout']);
        } else {
            this._toastr.error('Please Add Payment Card Info');
            this.paymentForm.markAllAsTouched();
            var scrollDiv = document.getElementById("payment").offsetTop;
            window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
        }


    }
    regexValidateZip2(event) {
        let billingForm = this.billingAddresstForm ? this.billingAddresstForm.getRawValue() : {};
        var regxExp = this._RgetRegexService.getRegex(billingForm.CountryCode);
        var filter = new RegExp(regxExp);
        if (filter.test(event.target.value)) {
            this.invalid_zip2 = false;
        } else if (event.target.value && (event.target.value.indexOf(' ') != 0)) {
            this.invalid_zip2 = true;
        }
        else {
            this.invalid_zip2 = false;
        }
    }
}
