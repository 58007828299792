import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import {BasicAuthInterceptor} from './basic-auth.interceptor';
import {ErrorInterceptor} from './http-error.interceptor';
import { FilterPipeModule } from 'ngx-filter-pipe';
import 'hammerjs';
import * as _ from 'underscore';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';

import { ToastrModule } from 'ngx-toastr';
import { AuthGuard } from './auth.guard';
import { AuthService } from './shared/auth.service';

import {PagesModule} from 'app/main/pages.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
const appRoutes: Routes = [
    {
        path     : 'login',
        loadChildren :() => import('./LogIn/login.module').then(a=>a.LoginModule),
        //canActivate: [PreventLoggedInAccess]
    },
    {
        path      : '',
        redirectTo: '/login',
        pathMatch: 'full',
    },
    {
        path      : '**',
        redirectTo: '/login',
        pathMatch: 'full',
    }
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
          }),
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay             : 0,
            passThruUnknownUrl: true
        }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatExpansionModule,
        
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,

        // App modules
        PagesModule,
        LayoutModule,
        FilterPipeModule,
        // toaster
        ToastrModule.forRoot()
    ],
    bootstrap   : [
        AppComponent
    ],
    providers:[
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BasicAuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        AuthGuard,
        AuthService
    ]
})
export class AppModule
{
}
