import { Injectable } from '@angular/core';
import { BASE_URL,API_URL, objectToParams } from '../constants';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RestService {


  constructor(private _http: HttpClient) { }

  Delete(resultUrl: string){
    var access_token=JSON.parse(localStorage.getItem('authtoken'));
    const apiUrl = BASE_URL;
    var url = apiUrl + resultUrl;
    var httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json-patch+json; charset=UTF-8',
        'Authorization': "Bearer " +access_token
      })
    };

    return this._http.delete<HttpResponse<Object>>(url, httpOptions).pipe();
  }


  Put(resultUrl: string, requestParameterData: any){
    var access_token=JSON.parse(localStorage.getItem('authtoken'));
    const apiUrl = API_URL;
    var url = apiUrl + resultUrl;
    var httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " +access_token
      })
    };

    return this._http.put<HttpResponse<Object>>(url, requestParameterData, httpOptions).pipe();
  }


  Get(resultUrl: string){
    var url = API_URL + resultUrl;
    var access_token=JSON.parse(localStorage.getItem('authtoken'));
    var httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'Authorization': "Bearer " +access_token
      })
    };
    
    return this._http.get<HttpResponse<Object>>(url, httpOptions).pipe(tap());
  }


  Post(resultUrl: string, requestParameterData: any, timeout?: any){
    requestParameterData = objectToParams(requestParameterData);
    var access_token=JSON.parse(localStorage.getItem('authtoken'));
    const apiUrl = API_URL;
    var url = apiUrl + resultUrl;
    var httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'Authorization':  "Bearer " +access_token
      })
    };

    return this._http.post<HttpResponse<Object>>(url,requestParameterData, httpOptions).pipe(tap());
  }

  PostFile(resultUrl: string, requestParameterData: any, timeout?: any){
    const apiUrl = API_URL;
    var access_token=JSON.parse(localStorage.getItem('authtoken'));
    var url = apiUrl + resultUrl;
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Disposition': 'multipart/form-data',
        'Accept': 'application/json',
        'Authorization':"Bearer " +access_token
      })
    };

    return this._http.post<HttpResponse<Object>>(url,requestParameterData, httpOptions).pipe(tap());
  }

  Token(request) {
    var url = BASE_URL + '/token';
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'Accept': 'application/json'
      })
    };

    return this._http.post<HttpResponse<Object>>(url,request, httpOptions).pipe(tap());
  }
   
  
  GetCustomersDetails(req: any){
    var _url='/RecurringOrders/GetCustomersDetails' + req;
   return this.Get(_url);
  }

  VerifyAddress(req: any){
    var _url='/Comman/GetVerifedAddressResponse';
   return this.Post(_url, req);
  }

}
