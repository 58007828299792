import { Injectable } from '@angular/core';
import {RestService} from '../../shared/rest.service';
@Injectable({
    providedIn: 'root'
  })
export class CheckoutService 
{
    constructor(
        private _restService: RestService
    )
    {
    }

   
      getCountryList(request: any) {
        let _url = '/NonProfitEnrollment/GetCountryRegions';
        return this._restService.Post(_url, request);
      }
      GetCountryRegions(request: any) {
        let _url = '/NonProfitEnrollment/GetCountryRegions';
        return this._restService.Post(_url, request);
      }
      CreateAutoOrder(request: any) {
        let _url = '/RecurringOrders/CreateAutoOrder';
        return this._restService.Post(_url, request);
      }
      GetCustomerBilling(req: string){
        var _url='/RecurringOrders/GetCustomerBilling' + req;
       return this._restService.Get(_url);
    }
    SetCustomerBilling(request: any) {
      let _url = '/RecurringOrders/SetCustomerBilling';
      return this._restService.Post(_url, request);
    }
   
}
