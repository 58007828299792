import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import * as _ from 'underscore';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgpSortModule } from "ngp-sort-pipe";
import { FuseSharedModule } from '@fuse/shared.module';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { FuseSidebarModule } from '@fuse/components';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { SubItemComponent } from 'app/main/Products/Sub-Item/Sub-Item.component';
import { productInfoComponent } from 'app/main/Products/Product-info/Product-info.component';
import { ProductsComponent } from 'app/main/Products/Products.component';
import { ProductsService } from 'app/main/Products/Products.service';
import {AutoOrderSummaryModule} from 'app/main/AutoOrderSummary/AutoOrderSummary.module';
const routes: Routes = [
    {
        path     : 'Products',
        component: ProductsComponent
    }
];

@NgModule({
    declarations: [
        ProductsComponent,
        productInfoComponent,
        SubItemComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatSelectModule,
        MatTableModule,
        MatTabsModule,
        NgpSortModule,
        NgxChartsModule,
        FilterPipeModule,
        AutoOrderSummaryModule,
        MatSlideToggleModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseWidgetModule
    ], entryComponents :[
        productInfoComponent,
        SubItemComponent
      ],
    providers   : [
        ProductsService
    ]
})
export class ProductsModule
{
}

