import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import {MatStepperModule} from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {MatCardModule} from '@angular/material/card';
import {MatRadioModule} from '@angular/material/radio';
import { NgpSortModule } from "ngp-sort-pipe";
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '@fuse/components';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import {AutoOrderSummaryModule} from 'app/main/AutoOrderSummary/AutoOrderSummary.module'
import { CalendarModule as AngularCalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import * as _ from 'underscore';
import { CommonModule } from '@angular/common';
import {CalendarComponent} from './Calendar.component'
import { CheckoutService } from 'app/main/Checkout/Checkout.service';

const routes: Routes = [
  {
      path     : 'Calendar',
      component: CalendarComponent
  }
];

@NgModule({
  declarations: [CalendarComponent],
  imports     : [
    RouterModule.forChild(routes),
    AngularCalendarModule.forRoot({
        provide   : DateAdapter,
        useFactory: adapterFactory
    }),
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatTableModule,
    MatTabsModule,
    MatRadioModule,
    NgpSortModule,
    MatCardModule,
    MatStepperModule,
    NgxChartsModule,
    AutoOrderSummaryModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseWidgetModule,CommonModule
],
providers   : [
  CheckoutService
]
})
export class CalendarModule { }
