import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {  FormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import * as _ from 'underscore';
import { _ROUTER_CONFIG } from '@ngrx/router-store/src/router_store_module';
import { calculateAutoOrderService } from '../../shared/calculateAutoOrder.service';


@Component({
    selector: 'auto-order-summary',
    templateUrl: './AutoOrderSummary.component.html',
    styleUrls: ['./AutoOrderSummary.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class AutoOrderSummaryComponent implements OnInit {
    loginForm: FormGroup;
    isShow: boolean = false;
    customerDetail: any;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public _calculateAutoOrder: calculateAutoOrderService
    ) {
        this.customerDetail = this.customerDetail = JSON.parse(localStorage.getItem('customerDetail')) ? JSON.parse(localStorage.getItem('customerDetail')) : "";
    }
    removeSmartOrder = function (index, Quantity, parentId, check) {
        // CartQuantity = $scope.EnrollSession.CartQuantity - Quantity;
        let SmartOrders: any = this._calculateAutoOrder.getSmartOrderItems();
        for (var arrIndex = 0; arrIndex < SmartOrders.length; arrIndex++) {
            if ((!SmartOrders[arrIndex].ParentId || SmartOrders[arrIndex].ParentId === parentId) && SmartOrders[arrIndex].ItemCode == parentId) {
                SmartOrders.splice(arrIndex, 1);
            }
        }

        SmartOrders = _.filter(SmartOrders, function (item) {
            return item.ParentId != parentId;
        });
        var filterResult = _.filter(SmartOrders, function (item) { return item.ParentId !== null && item.ParentId !== undefined; });
        let SmartOrderSubItems: any;
        SmartOrderSubItems = _.groupBy(filterResult, 'ParentId');
        let SmartOrdersSubTotal = 0;
        let SmartOrderBVTotal = 0;
        _.each(SmartOrders, function (item) {
            SmartOrdersSubTotal = SmartOrdersSubTotal + (item.Quantity * item.Price);
            SmartOrderBVTotal = SmartOrderBVTotal + (item.Quantity * item.BV);
            localStorage.setItem("SmartOrdersSubTotal", JSON.stringify(SmartOrdersSubTotal));
            localStorage.setItem("SmartOrderBVTotal", JSON.stringify(SmartOrderBVTotal));

        });
        localStorage.setItem("SmartOrderItems", JSON.stringify(SmartOrders));
        localStorage.setItem("SmartOrderSubItems", JSON.stringify(SmartOrderSubItems));
        this._calculateAutoOrder.TotalItemLength(SmartOrders.length);
        this._calculateAutoOrder.SetSmartOrderItems(SmartOrders);
        this._calculateAutoOrder.SetSmartOrderSubItems(SmartOrderSubItems);
        this._calculateAutoOrder.SetSmartOrdersSubTotal(SmartOrdersSubTotal);
        this._calculateAutoOrder.SetSmartOrderBVTotal(SmartOrderBVTotal);
        if (!check && SmartOrders.length > 0) {
            this._calculateAutoOrder.calculate(this.customerDetail.ShipMethodID);
        } else {
            this._calculateAutoOrder.setIsCallCalculateOrder(false);
        }
    };


    /**
     * On init
     */
    ngOnInit(): void {
    }

}
