import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { InvoiceComponent } from './Invoice/Invoice.component';
import { Router } from '@angular/router';
import {calculateAutoOrderService} from '../../shared/calculateAutoOrder.service';

 

@Component({
    selector     : 'Complete',
    templateUrl  : './Complete.component.html',
    styleUrls    : ['./Complete.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})


export class CompleteComponent implements OnInit
{
   
    orderDetails:any;
    customerDetail:any;
    AutoShipStartDate:any;
    /**
     * Constructor
     *
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {ProjectDashboardService} _projectDashboardService
     */
    constructor(
        private _matDialog: MatDialog,
        private _router: Router,
        private _calculateAutoOrder:calculateAutoOrderService
    )
    {     
      this.orderDetails = JSON.parse(localStorage.getItem('orderDetails'));
      this.customerDetail = JSON.parse(localStorage.getItem('LoggedInUserData'));
      this.AutoShipStartDate = JSON.parse(localStorage.getItem('AutoShipStartDate'));
      this._calculateAutoOrder.TotalItemLength(0);
       localStorage.clear()
        };

       
       

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
      
    }
    addSmartOrder() {
        const dialogRef = this._matDialog.open(InvoiceComponent, {
          width: '650px',
          panelClass: 'Invoice',
          data: {
           
          }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result == 'Success') {
          }
        });
      }
      loginToOfiice() {
        
        window.open('https://iShopPurium.com/', '_blank');
      }
      toSmartOrder () {
         this._router.navigate(['ManageMonthlySubscription']);
      }
}



