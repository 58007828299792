import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-additional-smart-order',
    templateUrl: './additional-smart-order.component.html',
    styleUrls: ['./additional-smart-order.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class AdditionalSmartOrderComponent implements OnInit {
    dialogTitle: string;
  
    constructor(
        public matDialogRef: MatDialogRef<AdditionalSmartOrderComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
    ) {  
        this.dialogTitle = 'Additional SmartOrder Cancellation';
    }

    ngOnInit() {
    }

    YES()
    {
        this.matDialogRef.close('Success');
    }
}