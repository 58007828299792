import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-smart-order-count',
    templateUrl: './smart-order-count.component.html',
    styleUrls: ['./smart-order-count.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class smartOrderComponentCount implements OnInit {
    dialogTitle: string;
  
    constructor(
        public matDialogRef: MatDialogRef<smartOrderComponentCount>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
    ) {  
        this.dialogTitle = 'NUMBER OF MONTHLY SUBSCRIPTIONS';
    }

    ngOnInit() {
    }
}