import { Component, OnInit, ViewEncapsulation, ViewChild, HostListener, } from '@angular/core';
import * as _ from 'underscore';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { stepperService } from 'app/shared/stepper.service';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { ToastrService } from 'ngx-toastr';
import { productInfoComponent } from './Product-info/Product-info.component';
import { SubItemComponent } from './Sub-Item/Sub-Item.component';
import { MatDialog } from '@angular/material/dialog';
import { ProductsService } from 'app/main/Products/Products.service';
import { calculateAutoOrderService } from '../../shared/calculateAutoOrder.service';


@Component({
    selector: 'Products',
    templateUrl: './Products.component.html',
    styleUrls: ['./Products.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ProductsComponent implements OnInit {
    displayedColumns: string[] = ['Description', 'Price', 'qualities', 'action'];
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    items: any[] = [];
    ProductListgroupBy: any;
    ProductListgroupBySource: any;
    ProductCategoryList: any[] = [];
    SmartHealth: any[] = [];
    ImmuneHealth: any[] = [];
    HeadToToe: any[] = [];
    allSubcriptionProducts: any[];
    selectedProject: any;
    ProductCategoryListSource: any;
    imageUrl: string;
    SmartOrders: any[] = [];
    ProductCategory: any;
    ProductListgrouName: string;
    ItemName: any;
    customerDetail: any;
    checked: any = false;
    search: any = { 'Description': "" };
    SmartHealthSource: any;
    ImmuneHealthSource: any;
    HeadToToeSource: any;
    allSubcriptionProductsSource: any;
    dateNow = Date.now();
    expandSection = {};
    mobileView: any = false
    screenWidth: any;
    CategorySubTitle = {};
    CategoryClass = {};
    quickOrderDataSource: any;
    /**
     * Constructor
     *
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {ProductsService} _ProductsService
     */
    constructor(
        private _ProductsService: ProductsService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _toastr: ToastrService,
        private _matDialog: MatDialog,
        private _stepperService: stepperService,
        private _calculateAutoOrder: calculateAutoOrderService,
        private _router: Router
        
    ) {

        this._stepperService.SetStepperPage('Products');
        this._calculateAutoOrder.setIsCallCalculateOrder(false);
        this.SmartOrders = JSON.parse(localStorage.getItem('SmartOrderItems')) || [];
        this.customerDetail = JSON.parse(localStorage.getItem('customerDetail')) ? JSON.parse(localStorage.getItem('customerDetail')) : "";

       /* setTimeout(function () {*/

        /*},1000);*/

    }


    GetProducts() {
        this._fuseSplashScreenService.show();
        let request = {

            "CustomerType": "",
            "WebCategoryId": ""
        };

        this._ProductsService.getProducts(request).subscribe((res: any) => {
            if (res) {
                this.items = res.ItemResponseList;
                let SmartHealth = [];
                let ImmuneHealth = [];
                let HeadToToe = [];
                let ProductCategoryItem = [];
                let productsByCategory: any;
                productsByCategory = _.groupBy(this.items, 'CategoryID');
                this.ProductListgroupBy = [];
                for (let item in productsByCategory) {
                    ProductCategoryItem.push({ 'Category': productsByCategory[item][0].Category, 'CategoryID': item, 'CategoryItems': productsByCategory[item] });
                };
                this.ProductCategoryList = ProductCategoryItem;

                // this.ProductCategory is used to show all products with category wise dynamically, setting this false will show particular categories in group else product are in 
                this.ProductCategory = false;
                this.expandSection['baseline'] = true;
                this.items.forEach(function (item) {
                    if (item.CategoryID == 450) {
                        SmartHealth.push(item);
                    } else if (item.CategoryID == 451) {
                        ImmuneHealth.push(item);
                    }
                    else if (item.CategoryID == 452) {
                        HeadToToe.push(item);
                    }
                });
                this.SmartHealth = SmartHealth;
                this.ImmuneHealth = ImmuneHealth;
                this.HeadToToe = HeadToToe;

                //this.SmartHealthSource = (this.SmartHealth);
                //this.ImmuneHealthSource = (this.ImmuneHealth);
                //this.HeadToToeSource = (this.HeadToToe);

                this.allSubcriptionProducts = _.uniq(this.items, 'ItemCode');
                this.allSubcriptionProductsSource = (this.allSubcriptionProducts);
               
                this.sortData({ active: "Description", direction: 'asc' });
                this.quickOrderDataSource = new MatTableDataSource(this.allSubcriptionProductsSource);

                this.quickOrderDataSource.filterPredicate = (data:
                    any, filterValue: string) =>
                    data.Description.trim().toLowerCase().indexOf(filterValue) !== -1
                    || data.Price.toString().trim().toLowerCase().indexOf(filterValue) !== -1
                    || data.BusinessVolume.toString().trim().toLowerCase().indexOf(filterValue) !== -1;
                this.ProductListgroupBySource = (this.allSubcriptionProducts);
                this.allSubcriptionProducts = this.allSubcriptionProducts.filter(function (item) {
                    if (item.ItemCode != "11020" && item.ItemCode != '11020-RU' && item.ItemCode != "11050" && item.ItemCode != "11050-RU" && item.ItemCode != "11040") {
                        return item;
                    }
                });

                this._fuseSplashScreenService.hide();
            } else {
                this._fuseSplashScreenService.hide();
                this._toastr.error('Error');
            }
        }, error => {
            this._fuseSplashScreenService.hide();
            this._toastr.error(error.error.Message, 'Error');
        });
    }

    sortData(sort: Sort) {
        const data = this.allSubcriptionProductsSource.slice();
        if (!sort.active || sort.direction === '') {
            this.allSubcriptionProductsSource = data;
            return;
        }
        this.allSubcriptionProductsSource = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'Description': return this.compare(a.Description, b.Description, isAsc);
                case 'Price': return this.compare(a.Price, b.Price, isAsc);

                default: return 0;
            }
        });


    }
    sortDataByCategory(sort: Sort) {
        const data = this.ProductListgroupBySource.slice();
        if (!sort.active || sort.direction === '') {
            this.ProductListgroupBySource = data;
            return;
        }
        this.ProductListgroupBySource = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'Description': return this.compare(a.Description, b.Description, isAsc);
                case 'Price': return this.compare(a.Price, b.Price, isAsc);

                default: return 0;
            }
        });
    }
    compare(a: number | string, b: number | string, isAsc: boolean): any {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
    popupLoad(item, qty, type) {
        const dialogRef = this._matDialog.open(SubItemComponent, {
            width: '630px',

            panelClass: ['sub-item', 'sub-item-modal'],
            data: {
                item: item
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == 'Success') {
            }
        });
    }
    productInfo(item) {
        const dialogRef = this._matDialog.open(productInfoComponent, {
            width: '650px',

            panelClass: 'product-info',
            data: {
                item: item
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == 'Success') {
            }
        });
    }

    isquickShop() {
        this.checked = !this.checked;
    }

    GetItemByCategory(type) {
        if (type) {
            let filterItems = _.filter(this.allSubcriptionProducts,
                function (obj) {
                    return obj.CategoryID == type;
                });
            this.ProductListgroupBy = filterItems;
            this.ProductListgrouName = this.ProductListgroupBy[0].Category;
            this.ProductListgroupBySource = (filterItems);
            // setTimeout(() => {
            //    this.ProductListgroupBySource.sort = this.sort;
            // });

        }
        else {
            this.ProductListgroupBy = this.allSubcriptionProducts;
            this.ProductListgroupBySource = (this.allSubcriptionProducts);
            // setTimeout(() => {
            //    this.ProductListgroupBySource.sort = this.sort;
            // });
        }
    };

    ContinueSmartOrder(product, quantity, type) {
        if (parseInt(quantity, 10) <= 0) {
            return false;
        }
        this.SmartOrders = JSON.parse(localStorage.getItem('SmartOrderItems')) || [];
        var SmartOrder = _.find(this.SmartOrders, function (item) { return item.ItemCode == product.ItemCode && item.ParentId == product.ParentId; });
        if (!this.isItemRestrcited(product, this.SmartOrders)) {
            if (!SmartOrder) {
                this.SmartOrders.push({
                    ItemCode: product.ItemCode,
                    Description: product.Description,
                    Quantity: quantity ? quantity : 1,
                    Price: product.Price,
                    ShippingPrice: product.ShippingPrice,
                    TinyImage: product.TinyPicture,
                    SmallImage: product.SmallPicture,
                    BV: product.BusinessVolume,
                    CV: product.CommissionableVolume,
                    TaxablePrice: product.TaxablePrice,
                    LongDetail: product.LongDetail,
                    LongDetail2: product.LongDetail2,
                    LongDetail3: product.LongDetail3,
                    LongDetail4: product.LongDetail4,
                    ParentId: product.ParentId,
                    IsUSOnly: product.IsUSOnly ? product.IsUSOnly : false
                });
                localStorage.setItem("SmartOrderItems", JSON.stringify(this.SmartOrders));
            }
            else {
                SmartOrder.Quantity = SmartOrder.Quantity + 1;
                _.find(this.SmartOrders, function (item) {
                    if (item.ItemCode == product.ItemCode) {
                        item.Quantity = SmartOrder.Quantity;
                    }
                });
                localStorage.setItem("SmartOrderItems", JSON.stringify(this.SmartOrders));
            }
            this._calculateAutoOrder.TotalItemLength(this.SmartOrders.length);
            let SmartOrdersSubTotal = 0;
            let SmartOrderBVTotal = 0;
            this.SmartOrders = JSON.parse(localStorage.getItem('SmartOrderItems'));
            _.each(this.SmartOrders, function (item) {
                SmartOrdersSubTotal = SmartOrdersSubTotal + (item.Quantity * item.Price);
                SmartOrderBVTotal = SmartOrderBVTotal + (item.Quantity * item.BV);
                localStorage.setItem("SmartOrdersSubTotal", JSON.stringify(SmartOrdersSubTotal));
                localStorage.setItem("SmartOrderBVTotal", JSON.stringify(SmartOrderBVTotal));

            });
            this._calculateAutoOrder.SetSmartOrdersSubTotal(SmartOrdersSubTotal);
            this._calculateAutoOrder.SetSmartOrderBVTotal(SmartOrderBVTotal);
            var filterResult = _.filter(this.SmartOrders, function (item) { return item.ParentId !== undefined && item.ParentId !== null; });
            let SmartOrderSubItems: any;
            SmartOrderSubItems = _.groupBy(filterResult, 'ParentId');
            localStorage.setItem("SmartOrderBVTotal", JSON.stringify(SmartOrderBVTotal));
            this._calculateAutoOrder.SetSmartOrderItems(this.SmartOrders);
            this._calculateAutoOrder.SetSmartOrderSubItems(SmartOrderSubItems);
            if (!product.ParentId || product.ParentId == product.ItemCode) {
                this._toastr.success("Product Added to Cart.");
            }
        } else {
            if (!product.ParentId || product.ParentId == product.ItemCode) {
                this._toastr.error('You can buy only ' + product.RestrictQty + ' quantity of this item.');
            }
        }
    }

    isItemRestrcited(product, SmartOrders) {
        var isRestricted = false;
        var filterItem = { 'Quantity': "", };
        filterItem = _.find(SmartOrders, function (item) { return item.ItemCode == product.ItemCode; });
        if (product.RestrictQty && product.RestrictQty > 0 && (filterItem && filterItem.Quantity >= product.RestrictQty)) {
            isRestricted = true;
        }
        return isRestricted;
    };
    ItemSearch(ItemName) {
        if (!this.checked) {
            this.search.Description = ItemName;
            if (ItemName) {
                this.expandSection['baseline'] = true;
                this.expandSection['headtotoe'] = true;
                this.expandSection['allproducts'] = true;
            }
            else {
                this.expandSection['baseline'] = true;
                this.expandSection['headtotoe'] = false;
                this.expandSection['allproducts'] = false;
            }
        } else {
            if (!this.ProductCategory) {
                ItemName = ItemName.trim(); // Remove whitespace
                ItemName = ItemName.toLowerCase(); // MatTableDataSource defaults to lowercase matches
                this.quickOrderDataSource.filter = ItemName;
                if (this.quickOrderDataSource.paginator) {
                    this.quickOrderDataSource.paginator.firstPage();
                }
            } else {
                ItemName = ItemName.trim(); // Remove whitespace
                ItemName = ItemName.toLowerCase(); // MatTableDataSource defaults to lowercase matches
                this.ProductListgroupBySource.filter = ItemName;
                if (this.ProductListgroupBySource.paginator) {
                    this.ProductListgroupBySource.paginator.firstPage();
                }
            }
        }
    };

    GotoNext(): void {
        const SmartOrderSubItems = this._calculateAutoOrder.getSmartOrderSubItems();
        if (this.SmartOrders.length > 0 || Object.values(SmartOrderSubItems).length > 0) {
            this._calculateAutoOrder.calculate(this.customerDetail.ShipMethodID ? this.customerDetail.ShipMethodID : 0).then(
                () => this._router.navigate(['Calendar']));
        } else {
            this._toastr.error('Please select item');
        }

    }

    ExpandCollapseSection(sectionName, flag) {
        this.expandSection[sectionName] = flag;
    }

    /**
  * On init
  */
    ngOnInit(): void {
        window.scrollTo(0, 0);
        this.GetProducts();
        this.screenWidth = window.innerWidth;
        if (this.screenWidth < 550) {
            this.mobileView = true
            this.displayedColumns = ['Description', 'Price', 'action'];
        }

        this.CategorySubTitle = {
            "450": "Maintain your new healthy lifestyle and baseline nutrition with Daily Core 4.",
            "451": "Support healthy immune function with superfood nutrition.",
            "452": "These items are included in the <span><u>3 & Then Free Program.</u><span class='headtotoe_hover_text'>Order the same eligible product for 3 months in a row.Then receive special rewards points to get the 4th month free, on your next order.Must have an active Monthly Subscription on file for the duration of the promotion.This is Purium’s way of celebrating healthy habits that stick.It’s an additional 25% savings over the 4 months to help make superfood nutrition available and accessible to everyone.</span></span>",
            "453": "",
            "454": "",
            "455": "",
            "456": "",
            "457": "",
            "458": "",
            "459": ""

        };
        this.CategoryClass = {
            "450": "color_green",
            "451": "color_yellow",
            "452": "color_brown",
            "453": "color_green",
            "454": "color_green",
            "455": "color_green",
            "456": "color_green",
            "457": "color_green",
            "458": "color_green",
            "459": "color_green"

        };
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
      this.screenWidth = window.innerWidth;
      if(this.screenWidth<550){
          this.mobileView = true
          this.displayedColumns = ['Description', 'Price', 'action'];
      }
    }

}



