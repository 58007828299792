import { Injectable } from '@angular/core';
import {RestService} from '../../shared/rest.service';
@Injectable({
    providedIn: 'root'
  })
export class ProductsService 
{
    constructor(
        private _restService: RestService
    )
    {
    }
    
    getProducts(request: any) {
        let _url = '/RecurringOrders/GetAutoOrderItems';
        return this._restService.Post(_url, request);
      }

   
}
