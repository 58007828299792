import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as _ from 'underscore';
import { stepperService } from 'app/shared/stepper.service';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { CheckoutService } from 'app/main/Checkout/Checkout.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { calculateAutoOrderService } from '../../shared/calculateAutoOrder.service';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';


export interface PeriodicElement {
  unitPrice: string;
  id: number;
  bv: number;
  qty: string;
}

@Component({
    selector: 'Checkout',
    templateUrl: './Checkout.component.html',
    styleUrls: ['./Checkout.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class CheckoutComponent implements OnInit {
    displayedColumns: string[] = ['name', 'unitPrice', 'bv', 'qty', 'action'];
    dataSource: MatTableDataSource<any>;
    CustomerBillingInfo: any;
    checkout: any;
    USOnlyAutoOrderItems: any = [];
    Date: any;
    paymentCardType: any;
    paymentInfo: any;
    billingAddress: any;
    customerDetail: any;
    shippingAddress: any;
    action: string;
    AutoShipStartDate: any;
    shipMethodId: any;
    shipMethods: any;
    LoggedInUserData: any;
    SubscriptionProcessType:any;


    /**
     * Constructor
     *
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {ProductsService} _CheckoutService
     */
    constructor(
        private _CheckoutService: CheckoutService,
        private _toastr: ToastrService,
        public _calculateAutoOrder: calculateAutoOrderService,
        private _router: Router,
        private _stepperService: stepperService,
        private _fuseSplashScreenService: FuseSplashScreenService,
    ) {
        window.scrollTo(0, 0);
        this._stepperService.SetStepperPage('Checkout');
        this.customerDetail = JSON.parse(localStorage.getItem('customerDetail')) ? JSON.parse(localStorage.getItem('customerDetail')) : "";
        this._calculateAutoOrder.calculate(0);
        this.LoggedInUserData = JSON.parse(localStorage.getItem('LoggedInUserData'));
        this.paymentInfo = JSON.parse(localStorage.getItem('paymentInfo'));
        this.billingAddress = JSON.parse(localStorage.getItem('billingAddress'));
        this.shippingAddress = JSON.parse(localStorage.getItem('shippingAddress'));
        this._calculateAutoOrder.setIsCallCalculateOrder(true);
        let SmartOrderItems = JSON.parse(localStorage.getItem('SmartOrderItems'))
        this.dataSource = new MatTableDataSource(SmartOrderItems);
        this.action = this.customerDetail ? 'edit' : "";
        this.AutoShipStartDate = JSON.parse(localStorage.getItem("AutoShipStartDate"));
        this.SubscriptionProcessType = JSON.parse(localStorage.getItem("SubscriptionProcessType"))=='AutoOrderProcessTy1'?true:false;
    }

    /**
* Get Customer Billing Info
*/

    /**
* Create Auto Order
*/
    changeshipMethodId(ShipMethodID) {
        this._calculateAutoOrder.calculate(ShipMethodID);
    }

    CreateAutoOrder() {
        this._fuseSplashScreenService.show();
        let SmartOrderItems = JSON.parse(localStorage.getItem('SmartOrderItems'));
        let CustomShipMethods = JSON.parse(localStorage.getItem('CustomShipMethods'));
        let shippingForm = JSON.parse(localStorage.getItem("shippingAddress"));
        let paymentCardType = JSON.parse(localStorage.getItem("paymentCardType"));
        var itemDetail = [];
        var ParentItemCode;
        _.each(SmartOrderItems, function (obj) {
            if (obj.ParentId && obj.ParentId !== obj.ItemCode) {
                ParentItemCode = obj.ParentId.toString();
            }
            else {
                ParentItemCode = "";
            }
            itemDetail.push({ ItemCode: obj.ItemCode, Quantity: obj.Quantity, ParentItemCode: ParentItemCode });

        });
        let req = {
            "AutoOrderRequest": {
                "AutoOrderCartID": "",
                "AutoShipStartDate": this.AutoShipStartDate,
                "AutoshipType": 0,
                "BeginMonth": 0,
                "Details": itemDetail ? itemDetail : [],
                "ShipMethodID": this.shipMethodId ? this.shipMethodId : CustomShipMethods[0].ShipMethodID,
            },
            "Company": shippingForm.CompanyName ? shippingForm.CompanyName : "",
            "CustomerType": this.LoggedInUserData.CustomerType ? this.LoggedInUserData.CustomerType : 0,
            "Email": this.LoggedInUserData.Email ? this.LoggedInUserData.Email : "",
            "ShippingAddress": {
                "FirstName": shippingForm.FirstName,
                "LastName": shippingForm.LastName,
                "BusinessName": "",
                "Address1": shippingForm.Address1,
                "Address2": shippingForm.Address2 ? shippingForm.Address2 : "",
                "Address3": "",
                "City": shippingForm.City,
                "CountryCode": shippingForm.CountryCode,
                "State": shippingForm.State,
                "Zip": shippingForm.Zip
            },
            "FirstName": shippingForm.FirstName,
            "LastName": shippingForm.LastName,
            "Phone1": this.customerDetail.MobilePhone ? this.customerDetail.MobilePhone : "",
            "CustomerId": this.LoggedInUserData.CustomerID ? this.LoggedInUserData.CustomerID : "",
            "AutoOrderID": this.customerDetail.AutoOrderID ? this.customerDetail.AutoOrderID : 0,
            "PaymentType": paymentCardType == "1" ? "PrimaryCreditCard" : "SecondaryCreditCard",
            "ProcessType": JSON.parse(localStorage.getItem("SubscriptionProcessType"))=='AutoOrderProcessTy1'?0:1
        }
        this._CheckoutService.CreateAutoOrder(req).subscribe((res: any) => {
            if (res) {
                if (res.AutoOrderID) {
                    localStorage.setItem("orderDetails", JSON.stringify(res));
                    this._router.navigate(['Complete']);
                    this._fuseSplashScreenService.hide();
                } else {
                    this._toastr.error(res.Result.errorsField);
                    this._fuseSplashScreenService.hide();
                }
                this._fuseSplashScreenService.hide();
            }
            else {
                this._fuseSplashScreenService.hide();
                this._toastr.error('Something went wrong please try again');
            }

        }, error => {
            this._fuseSplashScreenService.hide();
            this._toastr.error('Something went wrong please try again');
        });
    }
    goBack() {
        this._router.navigate(['Billing']);
    }
    /**
  * On init
  */
    ngOnInit(): void {
        window.scrollTo(0, 0);
    }

    removeSmartOrder = function (itemCode) {
        // CartQuantity = $scope.EnrollSession.CartQuantity - Quantity;
        let SmartOrders: any = this._calculateAutoOrder.getSmartOrderItems();
        for (var arrIndex = 0; arrIndex < SmartOrders.length; arrIndex++) {
            if ((!SmartOrders[arrIndex].ParentId || SmartOrders[arrIndex].ParentId === itemCode) && SmartOrders[arrIndex].ItemCode == itemCode) {
                SmartOrders.splice(arrIndex, 1);
            }
        }

        SmartOrders = _.filter(SmartOrders, function (item) {
            return item.ParentId != itemCode;
        });
        var filterResult = _.filter(SmartOrders, function (item) { return item.ParentId !== null && item.ParentId !== undefined; });
        let SmartOrderSubItems: any;
        SmartOrderSubItems = _.groupBy(filterResult, 'ParentId');
        let SmartOrdersSubTotal = 0;
        let SmartOrderBVTotal = 0;
        _.each(SmartOrders, function (item) {
            SmartOrdersSubTotal = SmartOrdersSubTotal + (item.Quantity * item.Price);
            SmartOrderBVTotal = SmartOrderBVTotal + (item.Quantity * item.BV);
            localStorage.setItem("SmartOrdersSubTotal", JSON.stringify(SmartOrdersSubTotal));
            localStorage.setItem("SmartOrderBVTotal", JSON.stringify(SmartOrderBVTotal));

        });
        localStorage.setItem("SmartOrderItems", JSON.stringify(SmartOrders));
        localStorage.setItem("SmartOrderSubItems", JSON.stringify(SmartOrderSubItems));
        this._calculateAutoOrder.TotalItemLength(SmartOrders.length);
        this._calculateAutoOrder.SetSmartOrderItems(SmartOrders);
        this._calculateAutoOrder.SetSmartOrderSubItems(SmartOrderSubItems);
        this._calculateAutoOrder.SetSmartOrdersSubTotal(SmartOrdersSubTotal);
        this._calculateAutoOrder.SetSmartOrderBVTotal(SmartOrderBVTotal);
        if (SmartOrders.length > 0) {
            this._calculateAutoOrder.calculate(this.customerDetail.ShipMethodID);
        }
        this.dataSource = new MatTableDataSource(SmartOrders);
    };
    editDate() {
        this._router.navigate(['Calendar']);
        localStorage.setItem("isEditDate", JSON.stringify(false));
    }
}



