import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'underscore';
import { calculateAutoOrderService } from '../../../shared/calculateAutoOrder.service';
import { FuseSplashScreenService } from '../../../../@fuse/services/splash-screen.service';
import { ToastrService } from 'ngx-toastr';




@Component({
    selector: 'app-sub-item',
    templateUrl: './Sub-Item.component.html',
    styleUrls: ['./Sub-Item.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class SubItemComponent implements OnInit {
    customersForm: FormGroup;
    dialogTitle: string;
    action: string;
    userDetails: any;
    DynamikKitItem: any = [];
    SmartOrders: any[] = [];
    selectedCartItem: any;
    customerType: string;
    item: any;
    DynamikItems: any = [];
    constructor(
        public matDialogRef: MatDialogRef<SubItemComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _calculateAutoOrder: calculateAutoOrderService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _toastr: ToastrService
    ) {
        this._fuseSplashScreenService.show();
        // Set the defaults
        this.action = _data.action;
        // Set the private defaults
        this.dialogTitle = this._data.item.Description;
        this.item = this._data.item;
        for (let index = 0; index < this.item.KitMembers.length; index++) {
            if (this.item.KitMembers[index].KitMemberItems.length > 1) {
                this.DynamikItems.push(this.item.KitMembers[index].KitMemberItems[0].ItemCode);
            } else {
                this.DynamikItems.push("0");
            }

        }

        this.selectedCartItem = JSON.parse(localStorage.getItem('SmartOrderItems')) || [];
        this._fuseSplashScreenService.hide();
    }



    isItemRestrcited = function (product, selectedItems) {
        var isRestricted = false;
        var filterItem: any;
        filterItem = _.find(selectedItems, function (item) { return item.ItemCode == product.ItemCode; });
        if (product.RestrictQty && product.RestrictQty > 0 && (filterItem && filterItem.Quantity >= product.RestrictQty)) {
            isRestricted = true;
        }
        return isRestricted;
    };

    ngOnInit() {


    }



    InserDynamicKitItemNew() {
        for (let index = 0; index < this.item.KitMembers.length; index++) {
            if (this.item.KitMembers[index].KitMemberItems.length > 1) {
                for (let j = 0; j < this.item.KitMembers[index].KitMemberItems.length; j++) {
                    if (this.item.KitMembers[index].KitMemberItems[j].ItemCode == this.DynamikItems[index]) {
                        this.DynamikKitItem.push(this.item.KitMembers[index].KitMemberItems[j]);
                        break;
                    }
                }
            }
        }

        if (!this.isItemRestrcited(this.item, this.selectedCartItem)) {
            let subitem = this.item;

            var itemObj3 = {
                ItemCode: this.item.ItemCode,
                Description: this.item.Description,
                Quantity: 1,
                Price: this.item.Price,
                BusinessVolume: this.item.BusinessVolume,
                ParentId: this.item.ItemCode,
                IsUSOnly: this.item.IsUSOnly,
                TinyPicture: this.item.TinyPicture,
                SmallPicture: this.item.SmallPicture,
                LongDetail: this.item.LongDetail,
                LongDetail2: this.item.LongDetail2,
                LongDetail3: this.item.LongDetail3,
                LongDetail4: this.item.LongDetail4
            };
            this.ContinueSmartOrder(itemObj3, 1, 3);
            this.DynamikKitItem.forEach((item) => {
                var itemObj = {
                    ItemCode: item.ItemCode,
                    Description: item.Description,
                    Quantity: 1,
                    Price: 0,
                    BusinessVolume: 0,
                    ParentId: subitem.ItemCode,
                    IsUSOnly: subitem.IsUSOnly,
                    TinyPicture: "https://purium-api-dev.wsicloud.com/api/NonProfitEnrollment/GetImageData?fileName=" + item.ItemCode + ".png",
                    SmallPicture: "https://purium-api-dev.wsicloud.com/api/NonProfitEnrollment/GetImageData?fileName=" + item.ItemCode + ".png",
                    LongDetail: item.LongDetail,
                    LongDetail2: item.LongDetail2,
                    LongDetail3: item.LongDetail3,
                    LongDetail4: item.LongDetail4
                };
                this.ContinueSmartOrder(itemObj, 1, 3);
            });

            this.item.KitMembers.forEach((item) => {
                if (item.KitMemberItems.length === 1) {
                    //This for single item fetch            
                    var singleItem = item.KitMemberItems[0];
                    var itemObj2 = {
                        ItemCode: singleItem.ItemCode,
                        Description: singleItem.Description,
                        Quantity: 1,
                        Price: 0,
                        BusinessVolume: 0,
                        ParentId: subitem.ItemCode,
                        IsUSOnly: subitem.IsUSOnly,
                        TinyPicture: "https://purium-api-dev.wsicloud.com/api/NonProfitEnrollment/GetImageData?fileName=" + singleItem.ItemCode + ".png",
                        SmallPicture: "https://purium-api-dev.wsicloud.com/api/NonProfitEnrollment/GetImageData?fileName=" + singleItem.ItemCode + ".png",
                        LongDetail: singleItem.LongDetail,
                        LongDetail2: singleItem.LongDetail2,
                        LongDetail3: singleItem.LongDetail3,
                        LongDetail4: singleItem.LongDetail4
                    };
                    this.ContinueSmartOrder(itemObj2, 1, 2);
                }

                this.matDialogRef.close();
            });
        } else {
            this._toastr.error('Info', 'You can buy only ' + this.item.RestrictQty + ' quantity of this item.');
        }

    }

    ContinueSmartOrder(product, quantity, type) {
        if (parseInt(quantity, 10) <= 0) {
            return false;
        }
        this.SmartOrders = JSON.parse(localStorage.getItem('SmartOrderItems')) || [];
        var SmartOrder = _.find(this.SmartOrders, function (item) { return item.ItemCode == product.ItemCode && item.ParentId == product.ParentId; });
        if (!this.isItemRestrcited(product, this.SmartOrders)) {
            if (!SmartOrder) {
                this.SmartOrders.push({
                    ItemCode: product.ItemCode,
                    Description: product.Description,
                    Quantity: quantity ? quantity : 1,
                    Price: product.Price,
                    ShippingPrice: product.ShippingPrice,
                    TinyImage: product.TinyPicture,
                    SmallImage: product.SmallPicture,
                    BV: product.BusinessVolume,
                    CV: product.CommissionableVolume,
                    TaxablePrice: product.TaxablePrice,
                    LongDetail: product.LongDetail,
                    LongDetail2: product.LongDetail2,
                    LongDetail3: product.LongDetail3,
                    LongDetail4: product.LongDetail4,
                    ParentId: product.ParentId,
                    IsUSOnly: product.IsUSOnly ? product.IsUSOnly : false
                });
                localStorage.setItem("SmartOrderItems", JSON.stringify(this.SmartOrders));
            }
            else {
                SmartOrder.Quantity = SmartOrder.Quantity + 1;
                _.find(this.SmartOrders, function (item) {
                    if (item.ItemCode == product.ItemCode && item.ParentId == product.ParentId) {
                        item.Quantity = SmartOrder.Quantity;
                    }
                });
                localStorage.setItem("SmartOrderItems", JSON.stringify(this.SmartOrders));
            }
            this._calculateAutoOrder.TotalItemLength(this.SmartOrders.length);
            let SmartOrdersSubTotal = 0;
            let SmartOrderBVTotal = 0;
            this.SmartOrders = JSON.parse(localStorage.getItem('SmartOrderItems'));
            _.each(this.SmartOrders, function (item) {
                SmartOrdersSubTotal = SmartOrdersSubTotal + (item.Quantity * item.Price);
                SmartOrderBVTotal = SmartOrderBVTotal + (item.Quantity * item.BV);
                localStorage.setItem("SmartOrdersSubTotal", JSON.stringify(SmartOrdersSubTotal));
                localStorage.setItem("SmartOrderBVTotal", JSON.stringify(SmartOrderBVTotal));

            });
            this._calculateAutoOrder.SetSmartOrdersSubTotal(SmartOrdersSubTotal);
            this._calculateAutoOrder.SetSmartOrderBVTotal(SmartOrderBVTotal);
            var filterResult = _.filter(this.SmartOrders, function (item) { return item.ParentId !== undefined && item.ParentId != null; });
            let SmartOrderSubItems: any;
            SmartOrderSubItems = _.groupBy(filterResult, 'ParentId');
            localStorage.setItem("SmartOrderSubItems", JSON.stringify(SmartOrderSubItems));
            localStorage.setItem("SmartOrderBVTotal", JSON.stringify(SmartOrderBVTotal));
            this._calculateAutoOrder.SetSmartOrderItems(this.SmartOrders);
            this._calculateAutoOrder.SetSmartOrderSubItems(SmartOrderSubItems);
            if (!product.ParentId || product.ParentId == product.ItemCode) {
                this._toastr.success("Product Added to Cart.");
            }



        } else {
            if (!product.ParentId || product.ParentId == product.ItemCode) {
                this._toastr.error('You can buy only ' + product.RestrictQty + ' quantity of this item.');
            }
        }
    }



}