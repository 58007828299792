import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseSplashScreenService } from '../../../../@fuse/services/splash-screen.service';
import { ManageMonthlySubscriptionService } from 'app/main/ManageMonthlySubscription/ManageMonthlySubscription.service';
import { ToastrService } from 'ngx-toastr';



@Component({
    selector: 'app-smart-order',
    templateUrl: './smart-order.component.html',
    styleUrls: ['./smart-order.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class smartOrderComponent implements OnInit {
    customersForm: FormGroup;
    dialogTitle: string;
    action: string;
    userDetails: any;
    customerType: boolean;
    customerDetail: any;
    constructor(
        public matDialogRef: MatDialogRef<smartOrderComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _toastr: ToastrService,
        private _ManageMonthlySubscriptionService: ManageMonthlySubscriptionService,
    ) {  // Set the defaults
        this.action = _data.action;
        
        // Set the private defaults
        this.customerDetail = JSON.parse(localStorage.getItem('customerDetail')) ? JSON.parse(localStorage.getItem('customerDetail')) : "";
           
            this.dialogTitle = 'Confirmation page';
       
        this.customerType =  '21' ? true:false;
        this.customersForm = this._formBuilder.group({
            firstName: [this.action == 'edit' ? this._data.data.firstName : '', [Validators.required]],
            lastName: [this.action == 'edit' ? this._data.data.lastName : '', [Validators.required]],
            customerID: [this.action == 'edit' ? this._data.data.externalCustomerID : '', [Validators.required]],
            backOfficeID: [this.action == 'edit' ? this._data.data.backofficeID : '']
        });
    }

    ngOnInit() {
     
    }

    agree() {
        this._fuseSplashScreenService.show();
        let req = {
            AutoOrderID: this.customerDetail.AutoOrderID,
            Status: 2
        };
        this._ManageMonthlySubscriptionService.ChangeAutoOrderStatus(req).subscribe((res: any) => {
            if (!res.resultField.statusField) {
                this._toastr.success('Success', 'Your Monthly Subscription was cancelled.');
                this._fuseSplashScreenService.hide();
                this.matDialogRef.close('Success');

            }else {
                this._toastr.error(res.resultField.errorsField[0]);
                this._fuseSplashScreenService.hide();
                this.matDialogRef.close();
              }
            }, error => {
              
              this._toastr.error(error, 'Error');
              this._fuseSplashScreenService.hide();
              this.matDialogRef.close();
            });
        
    }
    doNotAgree() {
       

    }



}