import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { NgpSortModule } from "ngp-sort-pipe";

import { FuseSharedModule } from '@fuse/shared.module';

import {AutoOrderSummaryComponent } from './AutoOrderSummary.component';

@NgModule({
    declarations: [
        AutoOrderSummaryComponent
    ],
    imports     : [
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        FilterPipeModule,
        MatInputModule,
        NgpSortModule,
        FuseSharedModule
    ],
    exports     : [
        AutoOrderSummaryComponent
    ]

})
export class AutoOrderSummaryModule
{
}
