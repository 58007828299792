import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { ToastrService } from 'ngx-toastr';
import { stepperService } from 'app/shared/stepper.service';
import { calculateAutoOrderService } from '../../shared/calculateAutoOrder.service';
import { RgetRegexService } from '../../shared/getRegex.service';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { FormBuilder, FormGroup, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { CheckoutService } from 'app/main/Checkout/Checkout.service';
import { RestService } from '../../shared/rest.service';
import * as _ from 'underscore';
const trimValidator: ValidatorFn = (control: FormControl) => {
    if (control.value.startsWith(' ')) {
        return {
            'trimError': { value: 'This field is required' }
        };
    }
};
@Component({
    selector: 'Shipping',
    templateUrl: './Shipping.component.html',
    styleUrls: ['./Shipping.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ShippingComponent implements OnInit {
    shippingForm: FormGroup;
    paymentForm: FormGroup;
    customerDetail: any = {};
    action: string;
    CustomerBillingInfo: any;
    isShowPrimaryCreditCard: any = false;
    isShowsecondaryCreditCard: any;
    IsCustomerBillingInfo: boolean = false;
    countriesList: any[] = [];
    yearList: any[] = [];
    stateList1: any[] = [];
    stateList2: any[] = [];
    countriesListAll: any[] = [];
    BillAddress: string = 'Yes';
    currentYear = new Date().getFullYear();
    paymentCardType: any;
    isPrimaryCreditInfo: boolean = false;
    isSecondaryCreditInfo: any = false;
    isPaymentFieldShow: any = false;
    LoggedInUserData: any;
    isModifyCard: boolean = false;
    invalid_zip1: boolean = false;
    isaddressVerified: boolean = false;
    oldcountry: any;

    constructor(
        private _toastr: ToastrService,
        private _CheckoutService: CheckoutService,
        private _calculateAutoOrder: calculateAutoOrderService,
        private _formBuilder: FormBuilder,
        private _router: Router,
        private _stepperService: stepperService,
        public _RgetRegexService: RgetRegexService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _restService: RestService) {

        this._stepperService.SetStepperPage('Shipping');
        this.BillAddress = 'Yes';
        this.customerDetail = JSON.parse(localStorage.getItem('customerDetail')) ? JSON.parse(localStorage.getItem('customerDetail')) : {};
        this.LoggedInUserData = JSON.parse(localStorage.getItem('LoggedInUserData'));
        this.action = this.customerDetail ? 'edit' : "";
        if (JSON.parse(localStorage.getItem('isModifyCard'))) {
            this.isModifyCard = true;
        } else {
            this.isModifyCard = false;
        }
        for (let i = 0; i < 20; i++) {
            this.yearList.push({ key: (this.currentYear + i), value: (this.currentYear + i) });
        }
        this.shippingForm = this._formBuilder.group({
            CompanyName: [this.action == 'edit' ? this.customerDetail.CompanyName : ''],
            FirstName: [this.action == 'edit' ? this.customerDetail.FirstName : '', [Validators.required,trimValidator]],
            LastName: [this.action == 'edit' ? this.customerDetail.LastName : '', [Validators.required,trimValidator]],
            Address1: [this.action == 'edit' ? this.customerDetail.Address1 : '', [Validators.required,trimValidator]],
            Address2: [this.action == 'edit' ? this.customerDetail.Address2 : ''],
            MobilePhone: [this.action == 'edit' ? this.customerDetail.MobilePhone.replace(/[^0-9+]+/g, '') : '', [Validators.required,trimValidator, Validators.pattern(('^[^ ][0-9 ]+[^ ]$')),Validators.minLength(10)]],
            City: [this.action == 'edit' ? this.customerDetail.City : '', [Validators.required]],
            CountryCode: [this.action == 'edit' ? this.customerDetail.CountryCode : 'US', [Validators.required,trimValidator]],
            State: [this.action == 'edit' ? this.customerDetail.State : 'CA', [Validators.required,trimValidator]],
            Zip: [this.action == 'edit' ? this.customerDetail.ShippingZip : '', [Validators.required,trimValidator]]
        });
    }

    ngOnInit() {
        $("#container-3").scrollTop(0);
        this.getCountryList();
    }
    removeMarketSpecificItems() {
        this._calculateAutoOrder.calculate(0, true);
    }
    calculateAutoOrder() {
        this._calculateAutoOrder.calculate(0, true);
    };

    getCountryList() {
        this._fuseSplashScreenService.show();
        let getCountryRequest = { CountryCode: 'US' };
        this._CheckoutService.getCountryList(getCountryRequest).subscribe((res: any) => {
            if (res.countriesField) {

                this.countriesListAll = res.countriesField;
                this.countriesList = _.filter(res.countriesField, function (item) {
                    return (item.countryCodeField === 'US' || item.countryCodeField === 'CA');
                });
                let form = this.shippingForm.getRawValue();
                let CountryCode = form.CountryCode || res.countriesField[0].countryCodeField;
                this.shippingForm.get('CountryCode').setValue(CountryCode);
                if (this.isModifyCard) {
                    this.countryChange('US', 2, true);
                } else {
                    this.countryChange(this.shippingForm.value.CountryCode, 1, true);
                }
            } else {
                this._fuseSplashScreenService.hide();
                this._toastr.error('Error');
            }
        }, error => {
            this._fuseSplashScreenService.hide();
            this._toastr.error(error, 'Error');
        });
    }
    countryChange(countryCode, type, hideLoader) {

        if (["US"].indexOf(countryCode) == -1) {
            let SmartOrders = JSON.parse(localStorage.getItem('SmartOrderItems'));
            _.each(SmartOrders, function (item, index) {
                if (item.IsUSOnly === true && (item.ParentId == item.ItemCode || !item.ParentId)) {
                    this.USOnlyAutoOrderItems.push(item);
                }
            });

            if (type == 1) {
                localStorage.setItem("CountryCode", JSON.stringify(countryCode));
            }
            this.getStates(countryCode, type, hideLoader);
            this.invalid_zip1 = false;
        }
        else {
            if (type == 1) {
                localStorage.setItem("CountryCode", JSON.stringify(countryCode));
            }
            this.getStates(countryCode, type, hideLoader);
            this.invalid_zip1 = false;
        }
    }
    getStates(country, type, hideLoader) {
        if (!hideLoader) {
            this._fuseSplashScreenService.show();
        }
        let getStatesRequest = { CountryCode: country };
        this._CheckoutService.GetCountryRegions(getStatesRequest).subscribe((res: any) => {
            if (res.countriesField) {
                if (type === 1) {
                    this.stateList1 = res.regionsField;
                    if (this.oldcountry && this.oldcountry !== country) {
                        // this.shippingForm.get('Zip').setValue('');
                        this.shippingForm.controls.State.setValue(['US'].indexOf(country) > -1 ? res.regionsField[0].regionCodeField : (this.customerDetail.State || 'CA'));
                    }
                    // if (['US'].indexOf(this.shippingForm.value.CountryCode) > -1 && ['NE', 'KS'].indexOf(this.shippingForm.value.State) > -1) {
                    this.stateChange(this.shippingForm.value.State, 1);
                    // }
                    // else {
                    //     this.removeMarketSpecificItems();
                    //     //this._fuseSplashScreenService.hide();
                    // }
                    this.oldcountry = country;
                } else {
                    this._fuseSplashScreenService.hide();
                    this.stateList2 = res.regionsField;
                }
            } else {
                this._toastr.error('Error');
                this._fuseSplashScreenService.hide();
            }
        }, error => {
            this._fuseSplashScreenService.hide();
            this._toastr.error(error, 'Error');
        });
    }
    stateChange(stateCode, type) {
        if (JSON.parse(localStorage.getItem('State'))) {
            if (stateCode != JSON.parse(localStorage.getItem('State'))) {
                this.shippingForm.patchValue({
                    // Address1: '',
                    // City: '',
                    Zip: ''
                });
            }
            if(stateCode == this.customerDetail.State){
                this.shippingForm.patchValue({
                    Address1: this.customerDetail.Address1,
                    City: this.customerDetail.City,
                    Zip: this.customerDetail.ShippingZip
                });
            }
        }
        if (['US'].indexOf(this.shippingForm.value.CountryCode) > -1 && ['NE', 'KS'].indexOf(stateCode) > -1) {
            if (type === 1) {
                localStorage.setItem('State', JSON.stringify(stateCode));
                this.calculateAutoOrder();
            }
        }
        else {
            if (type === 1) {
                localStorage.setItem('State', JSON.stringify(stateCode));
                this.calculateAutoOrder();
            }
        }
    }


    GotoBack() {
        this._router.navigate(['Calendar']);
    }

    GotoNext() {
        if (this.shippingForm.valid) {
            // if (!this.isaddressVerified) {
            //     this._toastr.warning('Please Verify Address', 'Warning');
            //     return;
            // }
            let shippingform = this.shippingForm.getRawValue();
            let shippingAddress = {
                CompanyName: shippingform.CompanyName,
                FirstName: shippingform.FirstName,
                LastName: shippingform.LastName,
                Address1: shippingform.Address1,
                Address2: shippingform.Address2,
                City: shippingform.City,
                CountryCode: shippingform.CountryCode,
                State: shippingform.State,
                Zip: shippingform.Zip,
            };

            this.customerDetail = {
                CompanyName: shippingform.CompanyName,
                FirstName: shippingAddress.FirstName,
                LastName: shippingAddress.LastName,
                ShippingBusinessName: this.customerDetail.ShippingBusinessName,
                Email: this.customerDetail.Email ? this.customerDetail.Email : '',
                Address1: shippingform.Address1,
                Address2: shippingform.Address2,
                Address3: '',
                CountryCode: shippingform.CountryCode,
                State: shippingform.State,
                City: shippingform.City,
                ShippingZip: shippingform.Zip,
                MobilePhone: shippingform.MobilePhone,
                ShipMethodID: this.customerDetail.ShipMethodID ? this.customerDetail.ShipMethodID : 0,
                AutoOrderID: this.customerDetail.AutoOrderID ? this.customerDetail.AutoOrderID : '',
                NextRunDate: this.customerDetail.NextRunDate ? this.customerDetail.NextRunDate : '',
                CustomerID: this.customerDetail.CustomerID
            };

            localStorage.setItem('customerDetail', JSON.stringify(this.customerDetail));
            localStorage.setItem('shippingAddress', JSON.stringify(shippingAddress));
            this._router.navigate(['Billing']);
        } else {
            this.shippingForm.markAllAsTouched();
            this._toastr.error('Please Add Shipping Info');
        }
    }

    verifyAddress() {
        if (this.shippingForm.valid) {
            this._fuseSplashScreenService.show();
            const shippingform = this.shippingForm.getRawValue();
            const shippingAddress = {
                Address1: shippingform.Address1,
                City: shippingform.City,
                State: shippingform.State,
                CountryCode: shippingform.CountryCode,
                Zip: shippingform.Zip,
            };

            this._restService.VerifyAddress(shippingAddress).subscribe(
                (res: any) => {
                    if (res.Status === 1 && res.Data) {
                        this.isaddressVerified = true;
                        this._toastr.success('Address verified Successfully', 'Success');
                        var customerDetail = JSON.parse(localStorage.customerDetail);
                        customerDetail.FirstName = this.shippingForm.value.FirstName;
                        customerDetail.LastName = this.shippingForm.value.LastName;
                        customerDetail.MobilePhone = this.shippingForm.value.MobilePhone;
                        customerDetail.Address1 = this.shippingForm.value.Address1;
                        customerDetail.City = this.shippingForm.value.City;
                        customerDetail.ShippingZip = this.shippingForm.value.Zip;
                        customerDetail.State = this.shippingForm.value.State;
                        localStorage.setItem("customerDetail", JSON.stringify(customerDetail));
                        this._fuseSplashScreenService.hide();
                    }
                    else {
                        this.isaddressVerified = false;
                        this._toastr.error('Invalid Address', 'Error');
                        this._fuseSplashScreenService.hide();
                    }
                },
                error => {
                    console.log(error);
                    this.isaddressVerified = false;
                    this._fuseSplashScreenService.hide();
                    this._toastr.error('Invalid Address', 'Error');
                }
            );
        } else {
            this.shippingForm.markAllAsTouched();
            this._toastr.error('Please Add Shipping Info');
        }
    }

    regexValidateZip1(event) {
        let shippingForm = this.shippingForm ? this.shippingForm.getRawValue() : {};
        var regxExp = this._RgetRegexService.getRegex(shippingForm.CountryCode);
        var filter = new RegExp(regxExp);
        if (filter.test(event.target.value)) {
            this.invalid_zip1 = false;
        } else if (event.target.value && (event.target.value.indexOf(' ') != 0)) {
            this.invalid_zip1 = true;
        }
        else {
            this.invalid_zip1 = false;
        }
    }

}
