import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {MatCardModule} from '@angular/material/card';
import { FuseSharedModule } from '@fuse/shared.module';
import {MatRadioModule} from '@angular/material/radio';
import {MatExpansionModule} from '@angular/material/expansion';
import { FuseSidebarModule } from '@fuse/components';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { smartOrderComponent } from './Smart-Order/smart-order.component';
import { smartOrderComponentCount } from './Smart-Order-Count/smart-order-count.component';
import { AdditionalSmartOrderComponent } from './Additional-Smart-Order/additional-smart-order.component';
import { ManageMonthlySubscriptionComponent } from './ManageMonthlySubscriptioncomponent';
import { ManageMonthlySubscriptionService } from './ManageMonthlySubscription.service';
import { MatGridListModule } from '@angular/material/grid-list';
const routes: Routes = [
    {
        path     : 'ManageMonthlySubscription',
        component: ManageMonthlySubscriptionComponent
    }
];

@NgModule({
    declarations: [
        ManageMonthlySubscriptionComponent, smartOrderComponent, smartOrderComponentCount, AdditionalSmartOrderComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatSelectModule,
        MatExpansionModule,
        MatTableModule,
        MatRadioModule,
        MatTabsModule,
        MatCardModule,
        MatGridListModule,
        NgxChartsModule,

        FuseSharedModule,
        FuseSidebarModule,
        FuseWidgetModule
    ],  entryComponents :[
        smartOrderComponent,
        smartOrderComponentCount,
        AdditionalSmartOrderComponent
      ],
    providers   : [
        ManageMonthlySubscriptionService
    ]
})
export class ManageMonthlySubscriptionModule
{
}

