import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseSplashScreenService } from '../../../../@fuse/services/splash-screen.service';
import { ToastrService } from 'ngx-toastr';



@Component({
    selector: 'app-invoice',
    templateUrl: './Invoice.component.html',
    styleUrls: ['./Invoice.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class InvoiceComponent implements OnInit {
    customersForm: FormGroup;
    dialogTitle: string;
    action: string;
    userDetails: any;
    customerType: boolean;
    constructor(
        public matDialogRef: MatDialogRef<InvoiceComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _toastr: ToastrService
    ) {  // Set the defaults
        this.action = _data.action;
        // Set the private defaults
       
            this.dialogTitle = 'Confirmation page';
       
        this.customerType =  '21' ? true:false;
        this.customersForm = this._formBuilder.group({
            firstName: [this.action == 'edit' ? this._data.data.firstName : '', [Validators.required]],
            lastName: [this.action == 'edit' ? this._data.data.lastName : '', [Validators.required]],
            customerID: [this.action == 'edit' ? this._data.data.externalCustomerID : '', [Validators.required]],
            backOfficeID: [this.action == 'edit' ? this._data.data.backofficeID : '']
        });
    }

    ngOnInit() {
     
    }

    agree() {
       

    }
    doNotAgree() {
       

    }



}