import { Component,  ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { Subject } from 'rxjs';
import * as _ from 'underscore';
import { Router } from '@angular/router';
import { stepperService } from 'app/shared/stepper.service';
import { fuseAnimations } from '@fuse/animations';
import { ToastrService } from 'ngx-toastr';
import { calculateAutoOrderService } from '../../shared/calculateAutoOrder.service';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { CalendarMonthViewDay, CalendarView, } from 'angular-calendar';
import { ManageMonthlySubscriptionService } from 'app/main/ManageMonthlySubscription/ManageMonthlySubscription.service';
import * as moment from "moment";
import { startOfDay, startOfWeek, addWeeks, subWeeks, startOfMonth, endOfWeek, subMonths, addMonths, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth } from 'date-fns';

type CalendarPeriod = 'month';

@Component({
    selector: 'app-calendar',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './Calendar.component.html',
    styleUrls: ['./Calendar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class CalendarComponent {
    currentYear = new Date().getFullYear();
    viewDate: any = new Date();
    dateNow = Date.now();
    view: CalendarView = CalendarView.Month;
    CalendarView = CalendarView;
    refresh: Subject<any> = new Subject();
    activeDayIsOpen: boolean = true;
    datesDisabled: any;
    _selectedDay: any;
    AutoShipStartDate: any;
    customerDetail; any;
    isEditDate: boolean = false;
    prevBtnDisabled: boolean = false;
    LoggedInUserData: any;
    nextBtnDisabled: boolean = false;
    minDate: Date = subMonths(new Date(), 0);
    maxDate: Date = addMonths(new Date(), 1);
    disableDaysOfMonth = [22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
    paymentCardType: any;
    SubscriptionProcessType:any;
    constructor(
        private _toastr: ToastrService,
        private _calculateAutoOrder: calculateAutoOrderService,
        private _ManageMonthlySubscriptionService: ManageMonthlySubscriptionService,
        private _stepperService: stepperService,
        private _router: Router,
        private _fuseSplashScreenService: FuseSplashScreenService,) {

        this.customerDetail = JSON.parse(localStorage.getItem('customerDetail')) ? JSON.parse(localStorage.getItem('customerDetail')) : "";

        this.viewDate = this.customerDetail.NextRunDate ? this.customerDetail.NextRunDate : moment().add(1, 'months').date(1).format('MM/DD/YYYY');
        this.AutoShipStartDate = this.customerDetail.NextRunDate ? JSON.parse(localStorage.getItem('AutoShipStartDate')) ? JSON.parse(localStorage.getItem('AutoShipStartDate')) : moment(this.customerDetail.NextRunDate).format('MM/DD/YYYY') : JSON.parse(localStorage.getItem('AutoShipStartDate')) ? JSON.parse(localStorage.getItem('AutoShipStartDate')) : "";
        if (this.AutoShipStartDate != '') {
            localStorage.setItem("AutoShipStartDate", JSON.stringify(this.AutoShipStartDate));
        }
        if (JSON.parse(localStorage.getItem('isEditDate'))) {
            this.isEditDate = true;
        } else {
            this.isEditDate = false;
        }
        this.LoggedInUserData = JSON.parse(localStorage.getItem('LoggedInUserData'));
        this._stepperService.SetStepperPage('Calendar');

        this._calculateAutoOrder.setIsCallCalculateOrder(true);
        this.SubscriptionProcessType = JSON.parse(localStorage.getItem('SubscriptionProcessType'));
    }
    ngOnInit() {
        window.scrollTo(0, 0);
    }
    beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {

        body.forEach((day) => {
            if (!this.dateIsValid(day.date)) {
                day.cssClass = 'cal-disabled';
            }
            const _selectedDay = moment(day.date).format('MM/DD/YYYY') === this.AutoShipStartDate ? day : "";
            if (_selectedDay) {
                /**
                 * Set selected day style
                 * @type {string}
                 */
                _selectedDay.cssClass = 'cal-selected';
            }
        });
    }
    
    dateIsValid(date: Date): boolean {
        //return parseInt(moment().add(1, "M").format('MM')) === date.getMonth()+1  && date.getTime() > new Date().getTime();
        //this.datesDisabled = this.getFirstAndThirdWed(moment().year(), moment().month());
        var isDisabled = true;
        if (moment(date) < moment()) {
            return false;
        }
        else if (this.disableDaysOfMonth.indexOf(Number(moment(date).format('D'))) > -1) {
            return true; 
        }
        // this.datesDisabled.forEach((day) => {
        //     if (moment(day).format('MM/DD/YYYY') == moment(date).format('MM/DD/YYYY')) {
        //         return isDisabled = false;
        //     }
        // });
        return isDisabled;
    }
    monthIsValid(date: Date): boolean {
        return date >= this.minDate && date <= this.maxDate;
    }
    dayClicked({ date, }: { date: Date; }): void {
        var isDateValid = false;
        if (isSameMonth(date, this.viewDate)) {
            isDateValid = true;
        }
        else if(this.maxDate.getMonth() >= date.getMonth()) {
            var dates = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            if (isSameMonth(date, dates)) {
                isDateValid = true;
            }
        }
        if(isDateValid){
            if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true)) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
            }
            this.viewDate = moment(date).format('MM/DD/YYYY');
            this.AutoShipStartDate = this.viewDate;
            localStorage.setItem("AutoShipStartDate", JSON.stringify(this.AutoShipStartDate));
            this.refresh.next();
            this.dateOrViewChanged();

        }
       
       
    }
    increment() {
        this.changeDate(this.addPeriod('month', this.viewDate, 1));
    }

    decrement(): void {
        this.changeDate(this.subPeriod('month', this.viewDate, 1));
    }
    dateOrViewChanged(): void {
        this.prevBtnDisabled = !this.monthIsValid(
            this.endOfPeriod(this.viewDate, this.subPeriod('month', this.viewDate, 1))
        );
        this.nextBtnDisabled = !this.monthIsValid(
            this.startOfPeriod(this.viewDate, this.addPeriod('month', this.viewDate, 1))
        );
        if (this.viewDate < this.minDate) {
            this.changeDate(this.minDate);
        } else if (this.viewDate > this.maxDate) {
            this.changeDate(this.maxDate);
        }
    }
    changeDate(date: Date): void {
        this.viewDate = date;
        this.dateOrViewChanged();
    }
    getFirstAndThirdWed(year, month) {
        let currmonth = (parseInt(month, 10) + 1);
        let arrdays = [];
        let dateinmonth = moment().daysInMonth();
        while (dateinmonth) {
            var current = moment().date(dateinmonth);
            arrdays.push(current);
            dateinmonth--;
        }

        var enabledDatesArr = [];
        for (var y = year; y <= 2030; y++) {
            for (var j = currmonth; j <= 12; j++) {
                //enabledDatesArr.push(moment(j + '/1/' + y).format('MM-DD-YYYY'));
                for (var i = 22; i <= 31; i++) {
                    var dat = j + '/' + i + '/' + y;
                    enabledDatesArr.push(moment(dat).format('MM-DD-YYYY'));
                }
            }
        }
        arrdays.forEach(element => {
            if (moment(element._d).isSameOrBefore(moment())) {
                enabledDatesArr.push(moment(element._d).format('MM-DD-YYYY'));
            }
        });
        return enabledDatesArr;
    }
    GotoBack() {
        if (this.isEditDate) {
            this._router.navigate(['/ManageMonthlySubscription']);
            return;
        }
        this._router.navigate(['/Products']);

    }
    GotoNext() {
        if (this.AutoShipStartDate) {
            this._router.navigate(['/Shipping']);
        } else {
            this._toastr.error('Please Choose a Date', 'Error');
        }

    }
    UpdateDate() {
        
        let SmartOrderItems = JSON.parse(localStorage.getItem('SmartOrderItems'));
        let CustomShipMethods = JSON.parse(localStorage.getItem('CustomShipMethods'));
        let AutoShipStartDate = JSON.parse(localStorage.getItem("AutoShipStartDate"));
        //let shippingForm =  JSON.parse(localStorage.getItem("shippingAddress"));
        let paymentCardType = JSON.parse(localStorage.getItem("paymentCardType"));
        var itemDetail = [];
        var ParentItemCode;
        _.each(SmartOrderItems, function (obj) {
            if (obj.ParentId && obj.ParentId !== obj.ItemCode) {
                ParentItemCode = obj.ParentId.toString();
            }
            else {
                ParentItemCode = "";
            }
            itemDetail.push({ ItemCode: obj.ItemCode, Quantity: obj.Quantity, ParentItemCode: ParentItemCode });

        });
        let req = {
            "AutoOrderRequest": {
                "AutoOrderCartID": "",
                "AutoShipStartDate": AutoShipStartDate,
                "AutoshipType": 0,
                "BeginMonth": 0,
                "Details": itemDetail ? itemDetail : [],
                "ShipMethodID": this.customerDetail.ShipMethodID ? this.customerDetail.ShipMethodID : CustomShipMethods[0].ShipMethodID,
            },
            "Company": "",
            "CustomerType": this.LoggedInUserData.CustomerType ? this.LoggedInUserData.CustomerType : 0,
            "Email": this.LoggedInUserData.Email ? this.LoggedInUserData.Email : "",
            "ShippingAddress": {
                "FirstName": this.customerDetail.FirstName,
                "LastName": this.customerDetail.LastName,
                "BusinessName": "",
                "Address1": this.customerDetail.Address1,
                "Address2": this.customerDetail.Address2 ? this.customerDetail.Address2 : "",
                "Address3": "",
                "City": this.customerDetail.City,
                "CountryCode": this.customerDetail.CountryCode,
                "State": this.customerDetail.State,
                "Zip": this.customerDetail.ShippingZip
            },
            "FirstName": this.customerDetail.FirstName,
            "LastName": this.customerDetail.LastName,
            "Phone1": this.customerDetail.MobilePhone ? this.customerDetail.MobilePhone : "",
            "CustomerId": this.LoggedInUserData.CustomerID ? this.LoggedInUserData.CustomerID : "",
            "AutoOrderID": this.customerDetail.AutoOrderID ? this.customerDetail.AutoOrderID : 0,
            "PaymentType": paymentCardType == "1" ? "PrimaryCreditCard" : "SecondaryCreditCard"
        }
        this._ManageMonthlySubscriptionService.CreateAutoOrder(req).subscribe((res: any) => {
            if (res) {
                if (res.AutoOrderID) {
                    this._toastr.success('Your Monthly Subscription Date Updated');
                    this._router.navigate(['ManageMonthlySubscription']);
                } else {
                    this._toastr.error(res.Result.errorsField);
                    this._fuseSplashScreenService.hide();
                }
                this._fuseSplashScreenService.hide();
            }
            else {
                this._fuseSplashScreenService.hide();
                this._toastr.error('Something went wrong please try again');
            }

        }, error => {
            this._fuseSplashScreenService.hide();
            this._toastr.error('Something went wrong please try again');
        });

    }

    UpdateDateNew() {
        this._fuseSplashScreenService.show();
        let AutoShipStartDate = JSON.parse(localStorage.getItem("AutoShipStartDate"));
        let req = {
            "AutoShipStartDate": AutoShipStartDate,
            "AutoOrderID": this.customerDetail.AutoOrderID
        };
        this._ManageMonthlySubscriptionService.UpdateAutoOrder(req).subscribe((res: any) => {
           
            if (res) {
                if (res.resultField && res.resultField.statusField == 0 ) {
                    this._toastr.success('Your Monthly Subscription Date Updated');
                    this._router.navigate(['ManageMonthlySubscription']);
                } else {
                    this._toastr.error(res.Result.errorsField);
                    this._fuseSplashScreenService.hide();
                }
                this._fuseSplashScreenService.hide();
            }
            else {
                this._fuseSplashScreenService.hide();
                this._toastr.error('Something went wrong please try again');
            }

        }, error => {
            this._fuseSplashScreenService.hide();
            this._toastr.error('Something went wrong please try again');
        });

        //for process type
        let reqProcessType = {
            AutoOrderID : this.customerDetail.AutoOrderID,
            ProcessType : this.SubscriptionProcessType == 'AutoOrderProcessTy1' ? 0 : 1,
        };     
        this._ManageMonthlySubscriptionService.UpdateAutoOrderProcessType(reqProcessType).subscribe(
            (res: any) => {
                if(res.resultField.statusField == 0)
                {
                  this._toastr.success("Your Monthly Subscription Process Type Updated");
                }
                else{
                    this._toastr.error("Error");
                }
        })

    }
    GetAutoOrders() {
        this._fuseSplashScreenService.show();
        let req = "";
        if (this.LoggedInUserData.CustomerID) {
            req = '?CustomerID=' + this.LoggedInUserData.CustomerID;
        } else if (this.LoggedInUserData.CustomerID && this.customerDetail.AutoOrderID) {
            req = '?CustomerID=' + this.LoggedInUserData.CustomerID ? this.LoggedInUserData.CustomerID : "", + '&AutoOrderID=' + this.customerDetail.AutoOrderID;
        }
        this._ManageMonthlySubscriptionService.GetAutoOrders(req).subscribe((res: any) => {
            if (res && res.AutoOrderList && res.AutoOrderList.length > 0) {
                this.paymentCardType = res.AutoOrderList[0].PaymentType.toString() == '0' ? '1' : '2';
                localStorage.setItem("paymentCardType", JSON.stringify(this.paymentCardType));
                this.UpdateDate();
            }
            else {
                this._fuseSplashScreenService.hide();
            }
        }, error => {
            console.log("error", error);
            this._toastr.error(error.error.Message, 'Error');
            this._fuseSplashScreenService.hide();
        });

    }


    startOfPeriod(period: CalendarPeriod, date: Date): Date {
        return {
            day: startOfDay,
            week: startOfWeek,
            month: startOfMonth,
        }['month'](date);
    }


    endOfPeriod(period: CalendarPeriod, date: Date): Date {
        return {
            day: endOfDay,
            week: endOfWeek,
            month: endOfMonth,
        }['month'](date);
    }

    addPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
        return {
            day: addDays,
            week: addWeeks,
            month: addMonths,
        }['month'](date, amount);
    }

    subPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
        return {
            day: subDays,
            week: subWeeks,
            month: subMonths,
        }['month'](date, amount);
    }

    processTypeChange(value)
    {
        localStorage.setItem("SubscriptionProcessType", JSON.stringify(value));
        this.SubscriptionProcessType = JSON.parse(localStorage.getItem('SubscriptionProcessType'));
    }
}
