import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { FuseSplashScreenService } from '../../@fuse/services/splash-screen.service';
import {RestService} from './rest.service';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as $ from "jquery";
@Injectable({
  providedIn: 'root'
})
export class stepperService {
  ItemLength:any;
  SmartOrderSubItems:any;
  SmartOrderItems:any;
  SmartOrdersSubTotal:any;
  SmartOrderBVTotal:any;
  customerDetail:any;
  SmartOrder:any;
  SmartOrderDetails:any ={};
  IsCallCalculateOrder:boolean= false;
  constructor( private _toastr: ToastrService,
    private _router: Router, private _fuseSplashScreenService: FuseSplashScreenService, private _http: HttpClient, private route: ActivatedRoute, private _restService:RestService) { 
    let SmartOrderItems = JSON.parse(localStorage.getItem('SmartOrderItems'));
    this.customerDetail = JSON.parse(localStorage.getItem('customerDetail'));
    this.SmartOrderDetails = JSON.parse(localStorage.getItem('SmartOrderDetails')) ? JSON.parse(localStorage.getItem('SmartOrderDetails')):{};
    this.SmartOrderItems = SmartOrderItems || [];
    this.IsCallCalculateOrder = false;
    this.ItemLength = SmartOrderItems ? SmartOrderItems.length : 0;
    this.SmartOrdersSubTotal = JSON.parse(localStorage.getItem('SmartOrdersSubTotal'));
    this.SmartOrderBVTotal =  JSON.parse(localStorage.getItem('SmartOrderBVTotal'));
    this.SmartOrderSubItems = JSON.parse(localStorage.getItem('SmartOrderSubItems')) || [];
  }

  SetStepperPage = function (page) {

    $('#steper').find('span#' + page).removeClass('complete1').addClass('complete');
    if (page == 'Enroll') {
        page = "Enrollment";
    }
    setTimeout(function () {
        $('#steper').find('span#' + page).nextAll().removeClass('complete').addClass('complete1');
        $('#steper').find('span#' + page).prevAll().removeClass('complete1').addClass('complete');
        $('#steper').find('span#' + page).addClass('complete');
    }, 1);
    if (!$('#steper').find('span#' + page).hasClass('complete1')) {
      this._router.navigate([page]);
        if (page == 'Enroll') {
            page = "Enrollment";
        }
        setTimeout(function () {
            $('#steper').find('span#' + page).nextAll().removeClass('complete').addClass('complete1');
            $('#steper').find('span#' + page).prevAll().removeClass('complete1').addClass('complete');
            $('#steper').find('span#' + page).addClass('complete');
        }, 1);
    }
};
  
}
