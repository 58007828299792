import { Injectable } from '@angular/core';
import {RestService} from '../../shared/rest.service';

@Injectable({
    providedIn: 'root'
})
export class ManageMonthlySubscriptionService {


    constructor(
        private _restService: RestService
    ) {
    }


    GetAutoOrders(req: string) {
        var _url = '/RecurringOrders/GetAutoOrders' + req;
        return this._restService.Get(_url);
    }
    ChangeAutoOrderStatus(request: any) {
        let _url = '/RecurringOrders/ChangeAutoOrderStatus'
        return this._restService.Post(_url, request);
    }
    GetCustomerBilling(req: string) {
        var _url = '/RecurringOrders/GetCustomerBilling' + req;
        return this._restService.Get(_url);
    }
    getCountryList(request: any) {
        let _url = '/NonProfitEnrollment/GetCountryRegions';
        return this._restService.Post(_url, request);
    }
    CreateAutoOrder(request: any) {
        let _url = '/RecurringOrders/CreateAutoOrder';
        return this._restService.Post(_url, request);
    }
    UpdateAutoOrder(request: any) {
        let _url = '/RecurringOrders/UpdateAutoOrder';
        return this._restService.Post(_url, request);
    }
    UpdateAutoOrderProcessType(request: any) {
        let _url = '/RecurringOrders/UpdateAutoOrderProcessType';
        return this._restService.Post(_url, request);
    }
    DeleteCard(request:any){
        console.log(request,"in delete card")
        let _url='/RecurringOrders/DeleteCard';
        return this._restService.Post(_url,request)
    }
    
}
