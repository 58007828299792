import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private _router: Router,) { }

  sendToken(token: any) {
    localStorage.setItem("authtoken",JSON.stringify(token.access_token))
  }

  sendUserData(data: string){
    localStorage.setItem("LoggedInUserData",data)
  }


  getToken() {
    return localStorage.getItem("authtoken");
  }

  getUserData(){
    return localStorage.getItem("LoggedInUserData")
  }


  isLoggedIn() {
    return this.getToken() !== null;
  }

  sendLanguageMenu(data: any){
    localStorage.setItem("LanguageMenuList",data)
  }
  getLanguageMenu() {
    return JSON.parse(localStorage.getItem("LanguageMenuList"));
  }

  sendDefaultCountryCode(data: any){
    localStorage.setItem("DefaultCountryCode",data)
  }
  sendDefaultLanguageCountryCode(data: any){
    localStorage.setItem("DefaultLanguageCountryCode",data)
  }
  logout() {
    localStorage.removeItem("LoggedInUser");
    localStorage.removeItem("LoggedInUserData");
    localStorage.removeItem("LanguageMenuList");
    localStorage.removeItem("DefaultCountryCode");
    localStorage.removeItem("DefaultLanguageCountryCode");
    this._router.navigate(["login"]);
  }

}
