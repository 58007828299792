import { Injectable } from '@angular/core';
import {RestService} from '../../shared/rest.service';

@Injectable({
    providedIn: 'root'
  })
export class CompleteService 
{


    constructor(
        private _restService: RestService
    )
    {
    }
    getProducts(request: any) {
        let _url = 'api/NonProfitEnrollment/GetOrderItems';
        return this._restService.Post(_url, request);
      }
}
